export default {
  'menu.home': '首页',
  'menu.login': '登录',
  'menu.register': '注册',
  'menu.register.result': '注册结果',
  'menu.user-management': '用户管理',
  'menu.user-management.information': '用户信息管理',
  'menu.user-management.relationship': '用户关系管理',
  'menu.user-management.sstudent': '学生查询',
  'menu.school-management': '学校管理',
  'menu.school-management.course': '课程设置',
  'menu.school-management.grade': '年级设置',
  'menu.school-management.create-school': '学校创建',
  'menu.school-management.basic-information': '学校列表管理',
  'menu.school-management.school-detail': '学校详情',
  'menu.school-management.class-management': '班级管理',
  'menu.school-management.semester-management': '学期管理',
  'menu.school-management.educational-administration': '教务人员管理',
  'menu.school-management.semester-class': '学期班级管理',
  'menu.business': '业务管理',
  'menu.business.administration': '业务列表',
  'menu.business.classification': '业务分类',
  'menu.orders': '订单管理',
  'menu.orders.list': '订单列表',
  'menu.account.logout': '退出登录',
  'menu.account.upwd': '修改密码',
  'menu.semester-management': '学期管理',
  'menu.semester-management.semester-management': '学期年级管理',
  'menu.semester-management.semester-class': '学期班级管理',
  'menu.semester-management.semester-details': '学期班级详情',
  'menu.semester-management.teacher': '教师管理',
  'menu.semester-management.student': '学生管理',
  'menu.semester-management.create-semester': '新建学期',
  'menu.semester-management.common-student-details': '学生详情',
  'menu.system': '系统管理',
  'menu.user-management.role': '角色管理',
  'menu.user-management.student-details': '学生详情',
  'menu.device': '设备管理',
  'menu.device.list': '设备列表',
  'menu.system.menu': '菜单管理',
  'menu.system.adhibition': '应用管理',
  'menu.system.armory': '资源管理',
  'menu.system.wechat-group': '微信组',
  'menu.system.wechat-application': '微信应用',
  'menu.system.wechat-reply': '微信自动回复',
  'menu.system.bulk': '批量导入',
  'menu.system.authority': '权限管理',
  'menu.interaction': '家校互动',
  'menu.interaction.message': '收发消息',
  'menu.interaction.message.send-message': '发短信',
  'menu.interaction.message.template-message': '短信模板',
  'menu.interaction.message.mass-student': '个性群发-学生',
  'menu.interaction.message.mass-teacher': '个性群发-教师',
  'menu.interaction.message.has-been-sent': '已发送',
  'menu.interaction.message.to-be-sent': '待发送',
  'menu.interaction.message.recommended-message': '推荐短信',
  'menu.interaction.home-work': '在线作业',
  'menu.interaction.home-work.assign-work': '布置作业',
  'menu.interaction.home-work.to-be-send-work': '待发送作业',
  'menu.interaction.home-work.sented-work': '已发作业',
  'menu.interaction.statistical-query': '统计查询',
  'menu.interaction.statistical-query.class-send': '班级发送',
  'menu.interaction.message.inbox': '收件箱',
  'menu.interaction.message.favorites': '收藏夹',
  'menu.dorm': '宿舍管理',
  'menu.dorm.menu': '宿舍楼管理',
  'menu.orders.batch-order-task': '批量订购业务',
  'menu.orders.order-details': '移动订购退订记录',
  'menu.school-management.join-klass-approve': '入班审批',
  'menu.school-management.report-notice': '报道须知',
  'menu.user-management.department': '部门管理',
  'menu.mschool': '家校互动',
  'menu.mschool.send-notice': '发送通知',
  'menu.mschool.home-work': '布置作业',
  'menu.mschool.custom': '自定义组管理',
  'menu.mschool.to-be-sent': '待发送',
  'menu.mschool.sent': '已发送',
  'menu.mschool.send-receive-details': '发送明细历史查询',
  'menu.business.administration-details': '货品详情',
  'menu.device.cards': '卡管理',
  'menu.device.attendance-record': '考勤记录',
  'menu.device.real-time-attendance-record': '考勤实时记录',
  'menu.attendance': '考勤管理',
  'menu.attendance.time-slot': '考勤时间段',
  'menu.device.list-details': '设备详情',
  'menu.business.brand': '品牌管理',
  'menu.semester-management.join-klass-approve': '入班审批',
  'menu.device.pic-feedbacks': '人脸识别照片反馈',
  'menu.attendance.attendance-record': '考勤记录',
  'menu.attendance.anomaly-users': '异常考勤管理',
  'menu.attendance.roll-call-search': '点名查询',
  'menu.attendance.roll-call-details': '点名明细查询',
  'menu.statement': '报表中心',
  'menu.statement.order-statement': '订购报表',
  'menu.semester-management.query-students': '学生查询',
  'menu.semester-management.student-details': '学生详情',
  'menu.semester-management.query-student-details': '学生详情',
  'menu.leave': '请假管理',
  'menu.leave.leave-flow': '请假流程',
  'menu.examscore': '考试与成绩',
  'menu.examscore.exam': '考试管理',
  'menu.examscore.score': '成绩管理',
  'menu.examscore.exam-details': '考试详情',
  'menu.examscore.score-details': '成绩详情',
  'menu.examscore.score-update': '成绩修改',
  'menu.examscore.student-exam-search': '学生成绩查询',
  'menu.orders.month-order': '月度对账单',
  'menu.orders.hebei-bank': '河北银行签约查询',
  'menu.system.appGroup': '应用组',
  'menu.leave.leave-query': '请假查询',
  'menu.quality.query': '评价查询',
  'menu.quality': '素质评价',
  'menu.quality.matter': '评价事项',
  'menu.quality.evaluation-query': '评价查询',
  'menu.system.sendbox-state': '消息发送状态查询',
  'menu.user-management.teaching-staff': '教职工管理',
  'menu.statement.evaluation-statistics': '素质评价统计',
  'menu.statement.leave-statistics': '请假统计',
  'menu.developer-tools': '开发工具',
  'menu.developer-tools.api-docs': '接口文档',
  'menu.developer-tools.api-docs.api-list': '接口文档列表',
  'menu.developer-tools.api-docs.api-detail': '接口文档详情',
  'menu.developer-tools.api-docs.api-diff': '接口差异',
  'menu.developer-tools.errcode-config': '错误码配置',
};
