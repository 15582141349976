import { Effect, EffectsCommandMap, Subscription } from 'dva';
import _ from 'lodash';
import { routerRedux } from 'dva/router';
import { MenuDataItem } from '@ant-design/pro-layout';
import { Dispatch, AnyAction } from 'redux';
import { History } from 'history';
import { getUserMenu, changeSchool } from '@/services/menu';
import { filterAuthorityButtons, filterMenu, handleAdminMenu, getUserStorage, isDevelopmentEnv, getAccessToken } from '@/utils/utils';
import React from 'react';
import * as d3 from 'd3-hierarchy'
import { router } from 'umi'
import { isAdmin, isSchool } from '@/utils/utils';

// import menuList from './menu-data';
const menuList = require("../../config/menu-data");
const mainMenu = require('../../config/menus/menu-main');
export interface IRouteComponent {
  path?: string;
  component: React.Component;
  routes?: IRouteComponent[];
  Routes?: string[];
  redirect?: string;
  [key: string]: any;
}
let g_allRoutes: IRouteComponent[] = [];

export interface MenuModelState {
  menuData: MenuDataItem[];
  buttonPaths: any[];
  menuTree: any[];
  // https://github.com/d3/d3-hierarchy/blob/v1.1.9/README.md#hierarchy
  menuHierarchy: object | null;
  allMenuCNs: MenuDataItem[];
  g_existRoutes: {
    [index: string]: IRouteComponent;
  };
  g_pathQuery: {
    [index: string]: object | undefined;
  };

}

export interface MenuModelType {
  namespace: 'menu';
  state: MenuModelState;
  effects: {
    changeSchool: Effect;
    getUserMenu: Effect;
    handleRouteChange: (
      action: { payload: { currentPath: string; currentQuery: object | string } },
      effects: EffectsCommandMap,
    ) => void;
    onTabChange: (action: { payload: { path: string } }, effects: EffectsCommandMap) => void;
    onTabEdit: (
      action: {
        payload: {
          targetKey: string;
          tabAction: tabsEditAction;
          currentPath: string;
        };
      },
      effects: EffectsCommandMap,
    ) => void;
  };
  reducers: {
    updateState: Effect;
    save: (
      state: MenuModelState,
      action: {
        payload: { menuData: MenuDataItem[] };
      },
    ) => MenuModelState;
    saveMenuCNs: (
      state: MenuModelState,
      action: {
        payload: { menuData: MenuDataItem[] };
      },
    ) => MenuModelState;
    saveAllRoutes: (state: MenuModelState, action: { payload: IRouteComponent }) => MenuModelState;
    addPath: (
      state: MenuModelState,
      action: {
        payload: {
          currentPath: string;
          currentComponent: IRouteComponent;
          currentQuery: object;
        };
      },
    ) => MenuModelState;
    removePath: (state: MenuModelState, action: { payload: { path: string } }) => MenuModelState;
    clearExistRoutes: (state: MenuModelState, action: AnyAction) => MenuModelState;
  };
  subscriptions: {
    onHistoryChange: Subscription;
    setup: Subscription
  };
}


function getFlattenMenuData(menu: MenuDataItem) {
  let result: MenuDataItem[] = [];
  if (menu.path && menu.name) {
    // console.log("route:", route.path);
    result.push(menu);
  }
  if (_.isArray(menu.children)) {
    _.map(menu.children, child => {
      result = result.concat(getFlattenMenuData(child));
    });
  }
  return result;
}

const MenuModel: MenuModelType = {
  namespace: 'menu',
  state: {
    menuData: menuList,
    menuTree: [],
    menuHierarchy: null,
    buttonPaths: [],
    allMenuCNs: [],
    g_existRoutes: {},
    g_pathQuery: {},
  },

  effects: {
    // 切换学校时传schoolId
    *changeSchool({ payload, callback }, { call, put }){
      try{
        const result = yield call(changeSchool, payload)
        let response = { ...result, httpStatus: result.response.status }
        if(callback){
          callback(response.httpStatus === 200 ? 'success' : 'error')
        }
      }catch(e){}
    },

    *getUserMenu({ payload }, { call, put }) {
      // console.log('getUserMenu',payload)
      try {
        const result = yield call(getUserMenu, payload);
        console.log('result-----------12121-----', result)
        let response = { ...result, httpStatus: result.response.status }
        let buttonPaths = filterAuthorityButtons(response.data)
        console.log('result----------------', result)
        // console.log('response----------', response, filterAuthorityButtons(response.data))

        localStorage.setItem('buttonPaths', JSON.stringify(buttonPaths))

        let menuData = []
        
        const localAllMenuCNs = _.flatten(_.map(menuList, menu => getFlattenMenuData(menu)));

        if (response.httpStatus === 200) {
          yield put({
            type: 'save',
            payload: {
              allMenuCNs: localAllMenuCNs,

            },
          });
          
          menuData = [mainMenu, ...filterMenu(response.data)] || []
            
          if(isAdmin()){
            menuData = handleAdminMenu(menuData)
          }
          // if (isDevelopmentEnv()) {
          //   menuData = filterMenu(menuList);
          // } 
          // 学校详情 hideInMenu admin身份不显示学校详情菜单,
          const schoolManagementMenu = _.find(menuData, menu => {
            return menu.path === '/school-management'
          }) || {}
          
          _.each(schoolManagementMenu.children || [], menu => {
            if(menu.path === '/school-management/school-detail'){
              menu.hideInMenu =  !!isAdmin()
            }
          })

          if(isSchool()){
            const useManagementMenu = _.find(menuData, menu => {
              return menu.path === '/user-management'
            }) || {}
  
            _.each(useManagementMenu.children || [], menu => {
              if(menu.path === '/user-management/information'){
                menu.name = '教职工管理'
              }
            })
          }
         
          yield put({
            type: 'updateState',
            payload: {
              menuHierarchy: d3.hierarchy({
                name: 'root',
                children: _.unionBy(menuData,'path'),
              }),
              menuData: _.unionBy(menuData,'path'),
              buttonPaths: buttonPaths || [],
            },
          });
          // TODO: 根据接口返回的 response.data 替换本地的 menuList. path为唯一的key，其他属性默认从本地读，若接口返回的有对应的key，则使用接口返回的，否则用本地的。
          // console.log('saveMenuCNs', menuList, menuData, response.data);
          const remoteMenuData = [mainMenu, ...filterMenu(response.data)] || [];
          const remoteAllMenuCNs = _.flatten(_.map(remoteMenuData, menu => getFlattenMenuData(menu)));
          const mergedAllMenuCNs = localAllMenuCNs.map((menu)=>{
            const remoteMenu = _.find(remoteAllMenuCNs, {path: menu.path})
            if (remoteMenu) {
              return remoteMenu
            } else {
              return menu
            }

          })
          // console.log("allMenuCNs", {localAllMenuCNs, remoteAllMenuCNs, mergedAllMenuCNs});

          yield put({
            type: 'updateState',
            payload: {
              allMenuCNs: mergedAllMenuCNs,

            },
          });
        }

      } catch (e) { }

    },

    *handleRouteChange({ payload: { currentPath, currentQuery } }, { select, put }) {
      if (!frameworkStartedDefer.isFulfilled) {
        yield frameworkStartedDefer.promise;
      }
      let { g_existRoutes } = yield select((state: { menu: MenuModelState }) => state.menu);

      const willRedirectRoute = _.first(
        _.filter(g_allRoutes, route => {
          return route.path == currentPath && _.has(route, 'redirect');
        }),
      );

      if (willRedirectRoute && willRedirectRoute.redirect) {
        yield put(
          routerRedux.replace({
            pathname: willRedirectRoute.redirect,
          }),
        );
        return;
      }
      const currentComponent = _.find(g_allRoutes, { path: currentPath });
      //每次列表跳转到详情都要更新g_pathQuery
      yield put({
        type: 'addPath',
        payload: { currentComponent, currentPath, currentQuery },
      });
    },
    *onTabChange({ payload: { path } }, { select, put }) {
      const { g_pathQuery } = yield select((state: { menu: MenuModelState }) => state.menu);
      let route = {
        pathname: path,
        query: g_pathQuery[path] || {},
      };
      yield put(routerRedux.push(route));
    },

    *onTabEdit({ payload }, { select, put }) {
      let { g_existRoutes, g_pathQuery } = yield select((state: { menu: MenuModelState }) => state.menu);
      const { targetKey, tabAction, currentPath } = payload;

      if (tabAction === 'remove') {
        if (Object.keys(g_existRoutes).length === 1) {
          return;
        }
        // delete g_existRoutes[targetKey]
        yield put({
          type: 'removePath',
          payload: { path: targetKey },
        });

        // if (Object.keys(g_existRoutes).length === 1) {
        //   yield routerRedux.push('/home');
        // }

        if (currentPath == targetKey) {
          const last = _.findLast(_.keys(g_existRoutes), item=> item!== targetKey)
          
          router.replace({
            pathname: last as string,
            query: g_pathQuery[last]
          })
        }
      }
    },
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    save(state, { payload }) {
      return {
        ...state,
        menuData: payload.menuData || [],
        buttonPaths: payload.buttonPaths || [],
      };
    },

    saveMenuCNs(state, { payload }) {
      const allMenuCNs = _.flatten(_.map(payload.menuData, menu => getFlattenMenuData(menu)));
      return {
        ...state,
        allMenuCNs,
      };
    },

    saveAllRoutes(state, action) {
      if (!frameworkStartedDefer.isFulfilled) {
        g_allRoutes = getChildrenRoute(action.payload || []);
        frameworkStartedDefer.resolve();
      }
      return state;
    },
    addPath(state, { payload }) {
      return {
        ...state,
        g_existRoutes: {
          ...state.g_existRoutes,
          [payload.currentPath]: payload.currentComponent,
        },
        g_pathQuery: {
          ...state.g_pathQuery,
          [payload.currentPath]: payload.currentQuery,
        },
      };
    },
    removePath(state, { payload }) {
      const g_existRoutes = {};
      _.each(state.g_existRoutes, (item, key) => {
        if (key !== payload.path) {
          g_existRoutes[key] = item;
        }
      });
      const g_pathQuery = {};
      _.each(state.g_pathQuery, (item, key) => {
        if (key !== payload.path) {
          g_pathQuery[key] = item;
        }
      });
      return {
        ...state,
        g_existRoutes,
        g_pathQuery,
      };
    },
    clearExistRoutes(state, action) {
      return {
        ...state,
        g_existRoutes: {},
        g_pathQuery: {},
      };
    },
  },

  subscriptions: {
    onHistoryChange({ dispatch, history }: { dispatch: Dispatch; history: History }) {
      const locationChange: History.LocationListener = location => {
       
        const { pathname, query } = (location as unknown) as { pathname: string; query: object };
        dispatch({
          type: 'handleRouteChange',
          payload: { currentPath: pathname, currentQuery: query },
        });
      };
      history.listen(locationChange);

      const { location: { pathname } } = history
      const isLoginPath = pathname.indexOf("login")
      const isInitPasswordPath = pathname.indexOf("/user/init-password")
      // 保证每次刷新页面都要调用  避免tabbar菜单不展示
      // console.log('getUserStorage',getUserStorage())
        if(getAccessToken() && getUserStorage() && !_.isEmpty(getUserStorage()) && isLoginPath === -1 && isInitPasswordPath === -1){
          console.log('isLoginPath',isLoginPath)
          dispatch({
            type: 'getUserMenu',
            payload: {
            },
          });
        }
    },

    setup({ dispatch, history }): void {
      history.listen(({ pathname }): void => {

        if (pathname === '/home' && getAccessToken()) {
          dispatch({
            type: 'getUserMenu',
            payload: {
            },
          });
        }
      });
    },
  },
};
export default MenuModel;

const getChildrenRoute = (route: IRouteComponent) => {
  let result: IRouteComponent[] = [];
  if (route.path && route.component) {
    result.push(route);
  } else {
    // 处理 redirect
    result.push(route);
  }
  if (_.isArray(route.routes)) {
    _.map(route.routes, childRoute => {
      result = result.concat(getChildrenRoute(childRoute));
    });
  }
  return result;
};

// 代码来源 qiankan/src/index.ts
class Deferred<T> {
  promise: Promise<T>;

  resolve!: (value?: T | PromiseLike<T>) => void;

  reject!: (reason?: any) => void;
  isFulfilled: boolean;

  constructor() {
    this.isFulfilled = false;
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    this.promise.finally(() => {
      this.isFulfilled = true;
    });
  }
}

// 用于保证，再获取到allRoutes后，其他部分再进行
const frameworkStartedDefer = new Deferred<void>();
