import { AnyAction, Reducer } from 'redux';
import { EffectsCommandMap, Subscription } from 'dva';
import {
  getProjects,
  getSubprojects,
  updateApidocsList,
  getApidocsList,
  getApidocsDetail,
  getApidiffsList,
  getApidiffsDetail,
  getApidiffsDiff
} from '../services/apiDocs';
import { Dispatch } from 'redux';
import _ from 'lodash';

export interface ParamsType {
  page?: number,
  pageSize?: number,
  project: string,
  subproject?: string,
  gitBranch?: string,
  version?: string
}

export interface DiffParamsType {
  page?: number,
  pageSize?: number,
  project?: string,
  subproject?: string,
  diffResult?: string,
  oldGitBranch?: string
  newGitBranch?: string
}

export interface ItemType {
  [key: string]: any
}

export interface PaginationType {
  page: number
  pageSize: number
  total: number
}
export interface StateType<T = any> {
  projects: ItemType[]
  subprojects: ItemType[]
  apiList: ItemType[]
  pagination: PaginationType
  queryParams: ParamsType
  diffPagination: PaginationType
  diffList: ItemType[]
  diffQueryParams: DiffParamsType
  apiDetail: ItemType
  diffDetail: ItemType
}

export type Effect = (
  action: AnyAction,
  effects: EffectsCommandMap & { select: <T>(func: (state: StateType) => T) => T },
) => void;


export interface ApiDocsProps {
  apiDocs: StateType
  dispatch: Dispatch
  loading: {
    effects: { [key: string]: boolean }
  }
}
export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    getProjects: Effect;
    getSubprojects: Effect;
    updateApidocsList: Effect;
    getApidocsList: Effect;
    getApidocsDetail: Effect;
    getApidiffsList: Effect;
    getApidiffsDetail: Effect;
    getApidiffsDiff: Effect;
  };
  reducers: {
    updateState: Reducer<StateType>;
  };
  subscriptions: { setup: Subscription };
}


const Model: ModelType = {
  namespace: 'apiDocs',
  state: {
    projects: [
      { name: '全部', value: '' }
    ],
    subprojects: [
      { name: '全部', value: '' }
    ],
    pagination: {
      page: 1,
      pageSize: 10,
      total: 0
    },
    apiList: [],
    queryParams: {
      page: 1,
      pageSize: 10,
      project: '',
      subproject: '',
      version: '',
      gitBranch: ''
    },
    diffPagination: {
      page: 1,
      pageSize: 10,
      total: 0
    },
    diffList: [],
    diffQueryParams: {
      page: 1,
      pageSize: 10,
    },
    apiDetail: {},
    diffDetail: {}
  },
  subscriptions: {
    setup({ dispatch, history }): void {
      history.listen(({ pathname }): void => {
        if (pathname.includes('/developer-tools/api-docs')) {
          dispatch({
            type: 'getProjects',
          });
        }
      });
    },
  },

  effects: {
    *getProjects({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getProjects);
        const response = { data: result.data, httpStatus: result.response.status }
        if (response.httpStatus === 200) {
          const projects = _.map(response.data, (item, index) => {
            return {
              name: item,
              value: item
            }
          })
          projects.unshift({ name: '全部', value: '' })
          yield put({
            type: 'updateState',
            payload: {
              projects
            }
          })
        }
      } catch (e) { }
    },
    *getSubprojects({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getSubprojects, payload);
        const response = { data: result.data, httpStatus: result.response.status }
        if (response.httpStatus === 200) {
          const subprojects = _.map(response.data, (item, index) => {
            return {
              name: item,
              value: item
            }
          })
          subprojects.unshift({ name: '全部', value: '' })
          yield put({
            type: 'updateState',
            payload: {
              subprojects
            }
          })
        }
      } catch (e) { }
    },
    *getApidocsList({ payload, callback }, { call, put, select }) {
      try {
        const { page, pageSize } = yield select(state => state.apiDocs.pagination);
        console.log('page, pageSize', page, pageSize)
        payload.page = page;
        payload.pageSize = pageSize;
        const result = yield call(getApidocsList, payload);
        const response = { data: result.data, httpStatus: result.response.status };
        if (response.httpStatus === 200) {
          const data = response.data;
          yield put({
            type: 'updateState',
            payload: {
              apiList: _.get(data, 'records', []),
              pagination: {
                page: _.get(data, 'current', 1),
                pageSize: _.get(data, 'pageSize', 20),
                total: _.get(data, 'total', 0),
              },
            }
          })
        }
      } catch (e) { }
    },
    *updateApidocsList({ payload, callback }, { call, put }) {
      try {
        const result = yield call(updateApidocsList, payload);
        let response = { ...result.data, httpStatus: result.response.status }
        if (callback && typeof callback === 'function') {
          callback(response && response.id ? 'success' : 'error');
        }
      } catch (e) { }
    },
    *getApidocsDetail({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getApidocsDetail, payload);
        let response = { data: result.data, httpStatus: result.response.status }
        if (response.httpStatus === 200) {
          const data = response.data;
          yield put({
            type: 'updateState',
            payload: {
              apiDetail: data
            }
          })
        }
      } catch (e) { }
    },
    *getApidiffsList({ payload, callback }, { call, put, select }) {
      try {
        const { page, pageSize } = yield select(state => state.apiDocs.diffPagination);
        payload.page = page;
        payload.pageSize = pageSize;
        const result = yield call(getApidiffsList, payload);
        const response = { data: result.data, httpStatus: result.response.status };
        if (response.httpStatus === 200) {
          const data = response.data;
          yield put({
            type: 'updateState',
            payload: {
              diffList: _.get(data, 'records', []),
              diffPagination: {
                page: _.get(data, 'current', 1),
                pageSize: _.get(data, 'pageSize', 20),
                total: _.get(data, 'total', 0),
              },
            }
          })
        }
      } catch (e) { }
    },
    *getApidiffsDetail({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getApidiffsDetail, payload);
        let response = { data: result.data, httpStatus: result.response.status }
        if (response.httpStatus === 200) {
          const data = response.data;
          yield put({
            type: 'updateState',
            payload: {
              diffDetail: data
            }
          })
        }
      } catch (e) { }
    },
    *getApidiffsDiff({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getApidiffsDiff, payload);
        let response = { ...result.data, httpStatus: result.response.status }
        if (callback && typeof callback === 'function') {
          callback(response && response.id ? 'success' : 'error');
        }
      } catch (e) { }
    },
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
