import { AnyAction, Reducer } from 'redux';
import { EffectsCommandMap, Subscription } from 'dva';
import { Dispatch } from 'redux';
import _ from 'lodash';
import {
  addParent
} from '@/services/addParent';

export interface AddParentState<T = any> {
  addParentVisible: boolean
  actionRecord: object
}

export type Effect = (
  action: AnyAction,
  effects: EffectsCommandMap & { select: <T>(func: (state: AddParentState) => T) => T },
) => void;

export interface ModelType {
  namespace: string;
  state: AddParentState;
  effects: {
    addParent: Effect;
  };
  reducers: {
    updateState: Reducer<AddParentState>;
  };
  subscriptions: { setup: Subscription };
}
export interface AddParentProps {
  dispatch: Dispatch;
  add_parent: AddParentState
}
const Model: ModelType = {
  namespace: 'add_parent',
  state: {
    addParentVisible: false,
    actionRecord: {},
  },

  subscriptions: {
    setup({ dispatch, history }): void {
      history.listen(({ pathname }): void => {
      });
    },
  },

  effects: {
    *addParent({ payload, callback }, { call, put, select }) {
      try {
        const { actionRecord } = yield select(state => state.add_parent)
        payload.studentId = actionRecord.studentId || payload.studentId
        const result = yield call(addParent, payload)
        let response = {
          data: result.data,
          httpStatus: result.response.status
        }
        callback(response.httpStatus === 200 ? 'success': 'error')
      } catch (e) { }
    },
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;