import { Effect, EffectsCommandMap } from 'dva';
import { getSchoolDetail } from '@/services/switchSchool';

interface SwitchSchoolModelState {
  schoolId: string | null,
  schoolInfo: {},
}

export interface SwitchSchoolModelType {
  namespace: string;
  state: SwitchSchoolModelState;
  effects: {
    getSchoolDetail: (
      action: { payload: { schoolId: string; schoolName: string; } },
      effects: EffectsCommandMap,
    ) => void;
  };
  reducers: {
    updateState: Effect;
  }
}

const SwitchSchoolModel: SwitchSchoolModelType = {
  namespace: 'switchSchool',
  state: {
    schoolId: null,
    schoolInfo: {},
  },
  effects: {
    *getSchoolDetail({ payload }, { call, put, select }) {
      try{
        const userState = yield select(state => state.user)
        const { data } = yield call(getSchoolDetail, payload)

        yield put({
          type: 'user/saveCurrentUser',
          payload: {
            ...userState.currentUser,
            schoolName: data.name,
            schoolId: data.id,
            province: data.province,
          },
        })

        yield put({
          type: 'updateState',
          payload: {
            schoolId: payload.schoolId,
            schoolInfo: data,
          },
        });
      }catch(e){}
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
}

export default SwitchSchoolModel;
