import { Reducer } from 'redux';
import { Subscription } from 'dva';
import _ from 'lodash'
import { Effect } from './connect.d';
import { NoticeIconData } from '@/components/NoticeIcon';
import { queryNotices } from '@/services/user';
import { PaginationProps } from 'antd/lib/pagination';
import * as d3 from 'd3-hierarchy';
import { 
  getWechatGroup, 
  getSemesterTree, 
  addAdminToSchool, 
  getUserByIdCard,
  getStudentByParentMobile, 
  createUser,
  getSchoolList,
  setSchoolAdmin 
} from '../services/global'

export interface NoticeItem extends NoticeIconData {
  id: string;
  type: string;
  status: string;
  
}

interface IRouteData {
  [key: string]: any
}

export interface GlobalModelState {
  collapsed: boolean;
  notices: NoticeItem[];
  wechatGroup: {
    list: any[],
    pagination: PaginationProps
  }
  payloadParams: {
    [key: string]: any
  } & PaginationProps
  gradeTree: object
  treeSelectData: object
  treeSelectValue: undefined
  idcardUser: object
  parentStudentList: object[]
  schoolList: object[]
}

export interface GlobalModelType {
  namespace: 'global';
  state: GlobalModelState;
  effects: {
    fetchNotices: Effect;
    clearNotices: Effect;
    changeNoticeReadState: Effect;
    getWechatGroup: Effect;
    getSemesterTree: Effect
    addAdminToSchool: Effect;
    getUserByIdCard: Effect
    createUser: Effect
    getStudentByParentMobile: Effect
    getSchoolList: Effect
    setSchoolAdmin: Effect
  };
  reducers: {
    changeLayoutCollapsed: Reducer<GlobalModelState>;
    saveNotices: Reducer<GlobalModelState>;
    saveClearedNotices: Reducer<GlobalModelState>;
    updateState: Reducer<GlobalModelState>;
  };
  subscriptions: { setup: Subscription };
}

const GlobalModel: GlobalModelType = {
  namespace: 'global',

  state: {
    collapsed: false,
    notices: [],
    wechatGroup: {
      list: [],
      pagination: {}
    },
    payloadParams: {},
    gradeTree: {},
    treeSelectData: {},
    treeSelectValue: undefined,
    idcardUser: {},
    parentStudentList: [],
    schoolList: []
  },

  effects: {
    *setSchoolAdmin({ payload, callback }, { call, put }) {
      try {
        const result = yield call(setSchoolAdmin, payload)
        let response = { ...result.data, httpStatus: result.response.status }
        if (callback && typeof callback === 'function') {
          callback(response.httpStatus == 200 ? "success" : "error");
        }
      } catch (e) { }
    },
    *getSchoolList({ payload, callback }, { call, put }){
      try {
        const result = yield call(getSchoolList, payload);
        let response = { ...result.data, httpStatus: result.response.status }
        if (response.httpStatus === 200 && _.isArray(response.records)) {
          yield put({
            type: 'updateState',
            payload: {
              schoolList: _.get(response, 'records', []).slice(0,10)
            }
          });
        }
        if (callback) callback();
      } catch (e) { }
    },
    *createUser({ payload, callback }, { call, put }) {
      try {
        const result = yield call(createUser, payload);
        let response = { ...result.data, httpStatus: result.response.status }
        if (callback && typeof callback === 'function') {
          callback(response.httpStatus == 200 || response.httpStatus == 201 ? "success" : "error");
        }
      } catch (e) { }
    },
    *getStudentByParentMobile({ payload, callback }, { call, put }){
      try{  
        const result = yield call(getStudentByParentMobile, payload);
        let response = { ...result.data, httpStatus: result.response.status }
        const parentStudentList = result.data || []
        if(response.httpStatus === 200){
          yield put({
            type: 'updateState',
            payload: {
              parentStudentList
            }
          })
        }
      }catch(e){}
    },
    *getUserByIdCard({ payload, callback }, { call, put }){
      try{  
        const result = yield call(getUserByIdCard, payload);
        let response = { ...result.data, httpStatus: result.response.status }
        const idcardUser = result.data || {}
        if(response.httpStatus === 200){
          yield put({
            type: 'updateState',
            payload: {
              idcardUser
            }
          })
        }
        return idcardUser
      }catch(e){}
    },
    *addAdminToSchool({ payload,callback }, { call, put }){
      try {
        const result = yield call(addAdminToSchool, payload);
        let response = { ...result.data, httpStatus: result.response.status }
        if (callback && typeof callback === 'function') {
          callback(response.httpStatus == 200 ? "success" : "error")
        }
      } catch (e) {}
    },
     // 获取学期年级班级树形菜单
     *getSemesterTree({ payload }, { call, put, select }) {
      try {
        const result = yield call(getSemesterTree, payload)
        const { currentUser } = yield select(state => state.user) 
        const { schoolInfo={}, schoolId } = yield select(state => state.switchSchool) 
        let response = { ...result.data, httpStatus: result.response.status }
        if (response.httpStatus === 200 && _.isArray(result.data)) {
         
          let rootData: IRouteData = {
            schoolId: _.get(currentUser,'schoolId') || schoolId || 0,
            schoolName: _.get(currentUser,'schoolName') || _.get(schoolInfo,'name','')
       
          }
          rootData.children = result.data
          let treeList = d3.hierarchy(rootData, function children(d) {
            return d.children;
          })
          treeList.each((d: IRouteData) => {
            let ids = ['schoolId','semesterId','semesterKlassId']
            let idKeys = ['schoolId','semesterId','semesterKlassId']
            let names = ['schoolName','semesterName','semesterKlassName']
            let depth = _.toNumber(d.depth)
            d.key = `${idKeys[depth]}-${d.data[ids[depth]]}`
            
            d.value = `${idKeys[depth]}-${d.data[ids[depth]]}`
            
            d.title= `${d.data[names[depth]]}`
           
          });
          // console.log('treeList',treeList)
          yield put({
            type: 'updateState',
            payload: {
              gradeTree: treeList
             
            }
          })
        }
      } catch (e) { }
    },
    // 微信组和微信应用
    *getWechatGroup({ payload, callback },{ call, put }){
      try {
        payload.pageSize = payload.pageSize || 10
        const result = yield call(getWechatGroup, payload);
        
        let response = { httpStatus: result.response.status }
        console.log('object',result)
        if (
          response.httpStatus === 200 && 
          result.data && 
          _.isArray(result.data.records)
          ) {
          const { pageSize,total,currentPage } = result.data
          yield put({
            type: 'updateState',
            payload: {
              wechatGroup: {
                list: result.data.records,
                pagination: {
                  showTotal: (total: number) => `共 ${total || 0} 条`,
                  showQuickJumper: true,
                  pageSize: pageSize || 10,
                  current: currentPage || 1,
                  total
                }
              },
              payloadParams: {
                ...payload
              }
            },
          });
        }

        if (callback) callback();
      } catch (e) { }
    },
    *fetchNotices(_, { call, put, select }) {
      const data = yield call(queryNotices);
      yield put({
        type: 'saveNotices',
        payload: data,
      });
      const unreadCount: number = yield select(
        state => state.global.notices.filter(item => !item.read).length,
      );
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: data.length,
          unreadCount,
        },
      });
    },
    *clearNotices({ payload }, { put, select }) {
      yield put({
        type: 'saveClearedNotices',
        payload,
      });
      const count: number = yield select(state => state.global.notices.length);
      const unreadCount: number = yield select(
        state => state.global.notices.filter(item => !item.read).length,
      );
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: count,
          unreadCount,
        },
      });
    },
    *changeNoticeReadState({ payload }, { put, select }) {
      const notices: NoticeItem[] = yield select(state =>
        state.global.notices.map(item => {
          const notice = { ...item };
          if (notice.id === payload) {
            notice.read = true;
          }
          return notice;
        }),
      );

      yield put({
        type: 'saveNotices',
        payload: notices,
      });

      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: notices.length,
          unreadCount: notices.filter(item => !item.read).length,
        },
      });
    },
  },

  reducers: {
    updateState(state,{payload}){
      return {
        ...state,
        ...payload
      }
    },
    changeLayoutCollapsed(state = { notices: [], collapsed: true }, { payload }): GlobalModelState {
      return {
        ...state,
        collapsed: payload,
      };
    },
    saveNotices(state, { payload }): GlobalModelState {
      return {
        collapsed: false,
        ...state,
        notices: payload,
      };
    },
    saveClearedNotices(state = { notices: [], collapsed: true }, { payload }): GlobalModelState {
      return {
        collapsed: false,
        ...state,
        notices: state.notices.filter((item): boolean => item.type !== payload),
      };
    },
  },

  subscriptions: {
    setup({ history }): void {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      history.listen(({ pathname, search }): void => {
        if (typeof window.ga !== 'undefined') {
          window.ga('send', 'pageview', pathname + search);
        }
      });
    },
  },
};

export default GlobalModel;
