import request from '@/utils/request'

export async function getSchoolDetail(params: any) {
  return request(`/school/schools/${params.schoolId}`, {
    params,
  });
}



