const mainMenu = require('./menus/menu-main');
const sysMenu = require('./menus/menu-sys');
const accountMenu = require('./menus/menu-account');
const schoolMenu = require('./menus/menu-school');
const semesterMenu = require('./menus/menu-semester');
const interactionMenu = require('./menus/menu-interaction');
const productMenu = require('./menus/menu-product');
const orderMenu = require('./menus/menu-order');
const deviceMenu = require('./menus/menu-device');
const dormMenu = require('./menus/menu-dorm');
const mschoolMenu = require('./menus/menu-mschool');
const examscoreMenu = require('./menus/menu-examscore');
const attendanceMenu = require('./menus/menu-attendance');
const statementMenu = require('./menus/menu-statement');
const leaveMenu = require('./menus/menu-leave');
const qualityMenu = require('./menus/menu-quality');
const developerToolsMenu = require('./menus/menu-developer-tools');


const allData = [
  mainMenu,
  sysMenu,
  accountMenu,
  schoolMenu,
  semesterMenu,
  mschoolMenu,
  examscoreMenu,
  attendanceMenu,
  leaveMenu,
  qualityMenu,
  dormMenu,
  deviceMenu,
  productMenu,
  orderMenu,
  statementMenu,
  // interactionMenu,
  developerToolsMenu
]

module.exports = allData;
