
if (window){
    console.warn("GIT_COMMIT:", process.env.GIT_COMMIT || "");
    console.warn("GIT_BRANCH:", process.env.GIT_BRANCH || "");
    window.GIT = {
      GIT_COMMIT: process.env.GIT_COMMIT || "",
      GIT_BRANCH: process.env.GIT_BRANCH || "",
    }
}

export default {
    codeProject: '爱家校', // 项目, 如老系统,爱家校
    codeSubProject: '管理后台', // 子项目,爱家校小程序, 一般按照git项目区分
    codeVersion: 'v1.6.6', // 代码版本号
    codeGitCommitId: window.GIT && window.GIT.GIT_COMMIT || ''
}