import request from '@/utils/request';


// 切换学校
export async function changeSchool(params: any){
  return request(`/oauth/gw/change-school/${params.schoolId}`,{
    method: 'POST'
  })
}

// 获取用户菜单
export async function getUserMenu(params:any) {
  return request(`/sys/menus/current-user`, {
    params,
  });
}