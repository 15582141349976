import { AnyAction, Reducer, Dispatch } from 'redux';
import { EffectsCommandMap, Subscription } from 'dva';
import _ from 'lodash';
import {
  addParent,
  getParentInformation,
  setMainParent,
  changeKlass,
  leaveSchool,
  getSemesterList,
  getSemesterClassList,
  deleteStudent,
  childMerge
} from '@/services/student'
import { enumDateTime } from '@/utils/utils';
import { FormComponentProps } from 'antd/es/form';

export interface StateType {
  parentList: any[];
  showEditModal: boolean;
  ParentListModal: boolean;
  actionRecord: {
    studentId?: number;
    [propName: string]: any;
  };
  changeKlassVisible: string
  studentRecord: {
    [key: string]: any
  }
  semesterList: any[]
  semesterKlassList: any[]
  schoolId: string | number
}

export type Effect = (
  action: AnyAction,
  effects: EffectsCommandMap & { select: <T>(func: (state: StateType) => T) => T },
) => void;

export interface DefaultStudentsProps extends FormComponentProps{
  dispatch: Dispatch<any>;
  styles: React.CSSProperties;
  loading: boolean;
  menu: any;
  studentAndparent: StateType;
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    addParent: Effect;
    getParentInformation: Effect;
    setMainParent:Effect;
    changeKlass: Effect;
    leaveSchool: Effect;
    getSemesterList: Effect;
    getSemesterClassList: Effect;
    deleteStudent: Effect
    childMerge: Effect
  }
  reducers: {
    updateState: Reducer<StateType>;
  }
  subscriptions: { setup: Subscription };
}

const Model: ModelType = {
  namespace: 'studentAndparent',
  state: {
    parentList: [],
    showEditModal: false,
    ParentListModal:false,
    actionRecord: {},
    changeKlassVisible: '',
    studentRecord: {},
    semesterList: [],
    semesterKlassList: [],
    schoolId: ''
  },
  subscriptions: {
    setup({ dispatch, history }): void {
      history.listen(({ pathname }): void => {
        if (pathname === '/') {

        }
      });
    },
  },
  effects: {
    *getSemesterClassList({ payload }, { call, put }) {
      payload.state = payload.state || 'ACTIVE'
      const result = yield call(getSemesterClassList, payload);
      let response = { ...result.data, httpStatus: result.response.status }
      if(response.httpStatus === 200){
        yield put({
          type: 'updateState',
          payload: {
            semesterKlassList: _.get(response, 'records', []),
          }
        });
      }
    },
    *getSemesterList({ payload }, { call, put }) {
      const result = yield call(getSemesterList, payload);
      let response = { ...result.data, httpStatus: result.response.status }

      if(response.httpStatus === 200){
        const semesterList = _.get(response, 'records', [])
        yield put({
          type: 'updateState',
          payload: {
            semesterList,
          }
        });
      }
    },
    *childMerge({ payload, callback }, { call, put, select }){
      try {
        const result = yield call(childMerge, payload)
        let response = {
          data: result.data,
          httpStatus: result.response.status
        }

        callback(response.httpStatus === 200 ? 'success': 'error')
      } catch (e) { }
    },
    *deleteStudent({ payload, callback }, { call, put, select }){
      try {
        const result = yield call(deleteStudent, payload)
        let response = {
          data: result.data,
          httpStatus: result.response.status
        }

        callback(response.httpStatus === 204 ? 'success': 'error')
      } catch (e) { }
    },
    *leaveSchool({ payload, callback }, { call, put, select }){
      try {
        const result = yield call(leaveSchool, payload)
        let response = {
          data: result.data,
          httpStatus: result.response.status
        }

        callback(response.httpStatus === 200 || response.httpStatus === 204  ? 'success': 'error')
      } catch (e) { }
    },
    *changeKlass({ payload, callback }, { call, put, select }){
      try {
        const result = yield call(changeKlass, payload)
        let response = {
          data: result.data,
          httpStatus: result.response.status
        }

        callback(response.httpStatus === 200 ? 'success': 'error')
      } catch (e) { }
    },
    *setMainParent({ payload, callback }, { call, put, select }){
      try {
        const result = yield call(setMainParent, payload)
        let response = {
          data: result.data,
          httpStatus: result.response.status
        }

        callback(response.httpStatus === 200 ? 'success': 'error')
      } catch (e) { }
    },

    *getParentInformation({ payload, callback }, { call, put, select }){
      try {
        const result = yield call(getParentInformation, payload)
        let response = {
          data: result.data,
          httpStatus: result.response.status
        }

        enumDateTime(_.get(response, 'data', []))
        if(response.httpStatus === 200){
          yield put({
            type: 'updateState',
            payload: {
              parentList: _.get(response, 'data', [])
            }
          })
        }
        callback(response.httpStatus === 200 ? 'success': 'error')
      } catch (e) { }
    },

    *addParent({ payload, callback }, { call, put, select }) {
      try {
        const { actionRecord } = yield select(state => state.studentAndparent)
        payload.studentId = actionRecord.studentId || payload.studentId
        const result = yield call(addParent, payload)
        let response = {
          data: result.data,
          httpStatus: result.response.status
        }
        callback(response.httpStatus === 200 ? 'success': 'error')
      } catch (e) { }
    },

  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  }
}

export default Model;



