import * as url from 'url'
import { getAuthority, dReloadAuthorized } from './Authorized';
import _ from 'lodash'

export const base = '/'

export function setAuthority(authority: string) {
  return localStorage.getItem('authority', authority);
}

window.url = url
window.base = base

window.genSwitchSchoolUrl = genSwitchSchoolUrl

/**
 * 给定schoolId，和目标path 比如 genSwitchSchoolUrl(17, '/home')
 * @param schoolId
 * @param pathurl
 */
export function genSwitchSchoolUrl(schoolId, pathurl = '/home') {
  const parsed = url.parse(location.href)
  parsed.pathname = `/${schoolId}/`
  parsed.hash = pathurl
  return url.format(parsed)
}

export function getPathname() {
  const parsed = url.parse(location.href)
  return parsed.pathname
}
export function genLoginPath() {
  const parsed = url.parse(location.href)
  parsed.pathname = '/'
  parsed.hash = '/user/login'
  return url.format(parsed)
}

// export function genSwitchUrl(schoolId) {
//   const params = getAppendParams(base, location.href)
//   const realAuthority = getAuthority()
//
//   if(!params) { // 如果没有使用本地储存的权限
//     dReloadAuthorized(realAuthority)
//   } else { // 如果有说明切换了学校
//     dReloadAuthorized(_.union(realAuthority, ['TEACHER']))
//   }
//
//
//   console.log('params', params)
//   // if(pa)
// }


/**
 * 如果没有返回 false,否则返回 [] 具有schoolId属性
 * @param href
 * @param base
 */
export function getAppendParams(base, href) {
  const parsed = url.parse(href)
  let params = parsed.pathname.slice(base.length)
  params = _.trim(params, '/');
  if(!params) {
    return false
  }

  const paramsArr = params.split('/')
  paramsArr.schoolId = paramsArr[0]
  return paramsArr
}

export const getAppendSchoolId = function() {
  const params = getAppendParams(base, window.location.href)
  if(!params) {
    return null
  }
  const schoolId = _.toNumber(params.schoolId)
  // console.log('isSafeInteger',_.isSafeInteger(schoolId))
  return _.isSafeInteger(schoolId) ? schoolId : null
}

export function autoHireTeachRole() {
  const schoolId = getAppendSchoolId()
  if (schoolId) { // 如果切换说明
    let currentAuthority = getAuthority()
    if (_.isString(currentAuthority)) {
      currentAuthority = [currentAuthority]
    }

    if (_.isArray(currentAuthority)) {
      currentAuthority.push('TEACHER')
    }
    dReloadAuthorized(currentAuthority)
  }
}
