import { Effect, Subscription } from 'dva';
import { Reducer } from 'redux';
import {message} from 'antd'
import _ from 'lodash';
import {
  getStudentInfo,
  getStudentDetails,
  getParentInfo,
  updateUser,
  getProducts,
  updateAtSchoolInfo,
  startOrder,
  orderIsTryOn,
  qiniuToken,
  unbindParent,
  getHebeiOrders,
  getHebeiStudentBalance,
  getHebeiStudentConsumption,
  hebeiInitTime,
} from '@/services/studentDetails';
import { getSchoolId, getUserStorage } from '@/utils/utils';

export interface StudentModelState {
  studentInfo: object;
  studentDetails: any[];
  parentInfo: any[];
  isShowEdit: boolean;
  isShowBussinessModal: boolean;
  isShowEidtSchoolInfo: boolean;
  qiniuToken: object;
  radioChecked: object;
  productList: any[];
  studentRecord: object;
  redirectUrl: string
  studentId: string
  ordered: boolean;
  studentBalance: React.ReactText
  currentTab: TabEnum
  hebeiOrders: {
    list: Array<Record<string,any>>
    pagination: Record<string,any>
  }
  hebeiOrdersParams: Record<string,any>
  HebeiStudentConsumption: {
    list: Array<Record<string,any>>
    pagination: Record<string,any>
  },
  HebeiStudentConsumptionParams: Record<string,any>
  HebeiStudentConsumptionRecordCount: Record<string,any>
}

export interface StudentModelType {
  namespace: 'studentDetails';
  state: StudentModelState;
  effects: {
    getStudentInfo: Effect;
    getStudentDetails: Effect;
    getParentInfo: Effect;
    updateUser: Effect;
    updateAtSchoolInfo: Effect;
    qiniuToken: Effect;
    getProducts: Effect;
    startOrder: Effect;
    orderIsTryOn: Effect
    unbindParent: Effect
    getHebeiOrders: Effect
    getHebeiStudentBalance: Effect
    getHebeiStudentConsumption: Effect
    hebeiInitTime: Effect
  };
  reducers: {
    updateState: Reducer<StudentModelState>;
  };
  subscriptions: { setup: Subscription };
}

export enum TabEnum {
  Parent='parent',
  CardRecharge='cardRecharge',
  CardConsumption='cardConsumption',
  CardRechargeError='cardRechargeError',
}

export const orderStatus = {
  '-1': '扣款失败',
  '0': '未缴费',
  '1': '未缴费',
  '2': '支付成功',
  '3': '交易成功'
}

const Model: StudentModelType = {
  namespace: 'studentDetails',

  state: {
    studentInfo: {},
    studentDetails: [],
    parentInfo: [],
    isShowEdit: false,
    isShowEidtSchoolInfo: false,
    isShowBussinessModal: false,
    radioChecked: {},
    qiniuToken: {},
    productList: [],
    studentRecord: {},
    redirectUrl: '',
    studentId: '',
    ordered: false,
    studentBalance: '',
    currentTab: TabEnum.Parent,
    hebeiOrders: {
      list: [],
      pagination: {}
    },
    hebeiOrdersParams: {},
    HebeiStudentConsumption: {
      list: [],
      pagination: {}
    },
    HebeiStudentConsumptionParams: {},
    HebeiStudentConsumptionRecordCount: {}
  },

  subscriptions: {
    setup({ dispatch, history }): void {
      history.listen((location): void => {
        if (
          location.pathname === '/user-management/student-details' ||
          location.pathname === '/semester-management/common-student-details' ||
          location.pathname === '/semester-management/query-student-details'
          ) {
            //处理当标签页有两个学生详情时，导致学生详情数据一样
          const studentId = _.get(window, 'g_history.location.query.studentId', 0);
          const schoolId = _.get(window, 'g_history.location.query.schoolId') || getSchoolId();

          const redirectUrl = _.get(window, 'g_history.location.query.redirectUrl')
          if(redirectUrl){
            dispatch({
              type: 'updateState',
              payload: {redirectUrl}
            })
          }
          dispatch({
            type: 'getStudentInfo',
            payload: {
              studentId
            }
          })

          dispatch({
            type: 'getStudentDetails',
            payload: {
              studentId
            }
          })

          dispatch({
            type: 'getParentInfo',
            payload: {
              studentId
            }
          })

          dispatch({
            type: 'updateState',
            payload: {
              schoolId,
              // currentTab: TabEnum.Parent
            }
          })
        }
      });
    },
  },

  effects: {

    *hebeiInitTime({ payload }, { call, put }){
      try{
        const result = yield call(hebeiInitTime,payload)
        console.log('hebeiInitTime',result)
        return result

      }catch(e){}
    },

    *getHebeiStudentConsumption({ payload }, { call, put }){
      try{
        const result = yield call(getHebeiStudentConsumption,payload)
        console.log('getHebeiStudentConsumption',result)
        if(_.get(result,'data.code') === '200'){
          const { rows, total, current, size, recordCount } = result.data
          yield put({
            type: 'updateState',
            payload: {
              HebeiStudentConsumption: {
                list: rows || [],
                pagination: {
                  showTotal: (total: number) => `共 ${Number(total) || 0} 条`,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  current: Number(current) || 1,
                  total: Number(total) || 0,
                  pageSize: Number(size) || 5,
                  pageSizeOptions: ['5','10','20','30']
                }
              },
              HebeiStudentConsumptionRecordCount: recordCount || {},
              HebeiStudentConsumptionParams: payload
            }
          })
        } else {
          yield put({
            type: 'updateState',
            payload: {
              HebeiStudentConsumption: {
                list: [],
                pagination: {}
              },
              HebeiStudentConsumptionRecordCount: {}
            }
          })
          if(_.get(result,'data.msg')){
            message.info(_.get(result,'data.msg'))
          }
        }
      }catch(e){}
    },

    *getHebeiStudentBalance({ payload }, { call, put }){
      try{
        const result = yield call(getHebeiStudentBalance,payload)
        console.log('getHebeiStudentBalance',result)
        if(_.get(result,'data.code') === '200'){
          yield put({
            type: 'updateState',
            payload: {
              studentBalance: _.get(result,'data.data') || ''
            }
          })
        } else {
          yield put({
            type: 'updateState',
            payload: {
              studentBalance: ''
            }
          })
          if(_.get(result,'data.msg')){
            message.info(_.get(result,'data.msg'))
          }
        }
      }catch(e){}
    },

    *getHebeiOrders({ payload }, { call, put }){
      try{
        const result = yield call(getHebeiOrders,payload)
        console.log('getHebeiOrders',result)

        if(_.get(result,'data.code') === '200'){
          const { rows, total, current, size } = result.data
          yield put({
            type: 'updateState',
            payload: {
              hebeiOrders: {
                list: rows || [],
                pagination: {
                  showTotal: (total: number) => `共 ${Number(total) || 0} 条`,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  current: Number(current) || 1,
                  total: Number(total) || 0,
                  pageSize: Number(size) || 5,
                  pageSizeOptions: ['5','10','20','30']
                }
              },
              hebeiOrdersParams: payload
            }
          })
        } else {
          yield put({
            type: 'updateState',
            payload: {
              hebeiOrders: {
                list: [],
                pagination: {}
              },
            }
          })
          if(_.get(result,'data.msg')){
            message.info(_.get(result,'data.msg'))
          }
        }
      }catch(e){}
    },
    *unbindParent({ payload }, { call, put }){
      try{
        const result = yield call(unbindParent,payload)
        let response = { ...result.data, httpStatus: result.response.status }
        return !!(response.httpStatus === 204 || response.httpStatus === 200)
      }catch(e){}
    },

    *qiniuToken({ payload, callback }, { call, put }){
      try{
        const result = yield call(qiniuToken,payload)
        let response = { ...result.data, httpStatus: result.response.status }
        if(response.httpStatus === 200 && result.data){

          yield put({
            type: 'updateState',
            payload: {
              qiniuToken: result.data
            }
          })
        }
        if(callback){
          callback(response.httpStatus === 200 ? 'success' : 'error')
        }
      }catch(e){}
    },

    *getStudentInfo({ payload, callback }, { call, put }){
      try{
        const result = yield call(getStudentInfo,payload)
        let response = { ...result.data, httpStatus: result.response.status }
        if(response.httpStatus === 200){
          yield put({
            type: 'updateState',
            payload: {
              studentInfo: result.data,
              studentId: payload.studentId,
            }
          })
        }
        if(callback){
          callback(response.httpStatus === 200 ? 'success' : 'error')
        }
      }catch(e){}
    },

    *getStudentDetails({ payload, callback }, { call, put }){
      try{
        const result = yield call(getStudentDetails,payload)
        let response = { ...result.data, httpStatus: result.response.status }
        if(response.httpStatus === 200){
          yield put({
            type: 'updateState',
            payload: {
              studentDetails: result.data
            }
          })
        }
        if(callback){
          callback(response.httpStatus === 200 ? 'success' : 'error')
        }
      }catch(e){}
    },

    *getParentInfo({ payload, callback }, { call, put }){
      try{
        const result = yield call(getParentInfo,{ ...payload, withOrderInfo: true })
        let response = { ...result.data, httpStatus: result.response.status }
        if(response.httpStatus === 200){
          yield put({
            type: 'updateState',
            payload: {
              parentInfo: result.data
            }
          })
        }
        if(callback){
          callback(response.httpStatus === 200 ? 'success' : 'error')
        }
      }catch(e){}
    },

    *startOrder({ payload, callback }, { call, put }){
      try {
        const result = yield call(startOrder, payload)

        let response = { ...result.data, httpStatus: result.response.status }
        if(callback){
          callback(response.httpStatus === 200 ? 'success' : 'error')
        }
      } catch (e) { }
    },

    *orderIsTryOn({ payload }, { call, put }){
      try {
        const result = yield call(orderIsTryOn, payload)
        let response = { ...result.data, httpStatus: result.response.status }
        console.log('orderIsTryOn',response)
        if (response.httpStatus === 200) {
          yield put({
            type: 'updateState',
            payload: {
              ordered: !!_.get(response,'ordered',false)
            }
          })
        }
        return response
      } catch (e) { }
    },

    *getProducts({ payload }, { call, put }){
      try {
        const result = yield call(getProducts, payload)
        // console.log('getSemesterTree', result)
        let response = { ...result.data, httpStatus: result.response.status }
        if (response.httpStatus === 200) {
          yield put({
            type: 'updateState',
            payload: {
              productList: result.data.records
            }
          })
        }
      } catch (e) { }
    },

    *updateUser({ payload, callback }, { call, put }) {
      try {
        const result = yield call(updateUser, payload);
        let response = { ...result.data, httpStatus: result.response.status }
        if (callback && typeof callback === 'function') {
          callback(response.httpStatus == 200 || response.httpStatus == 201 ? "success" : "error");
        }
      } catch (e) { }
    },

    *updateAtSchoolInfo({payload, callback },{ call, put }){
      try {
        const result = yield call(updateAtSchoolInfo, payload);
        let response = { ...result.data, httpStatus: result.response.status }
        if (callback && typeof callback === 'function') {
          callback(response.httpStatus == 200 || response.httpStatus == 201 ? "success" : "error");
        }
      } catch (e) { }
    }
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
