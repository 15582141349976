/**
 * 需在跳转页面前注册页面到菜单的关系
 *  - 直接写进state data （大部分情况下）
 *    state: {
        data: {
          '/some/path': ['页面标题', 'menuItem key', 'menuItem key2'],
        },
      }
 *  - 可以在程序初始化时
 *  - 在跳转前
 *  - 跳转后再次注册用于动态标题
 * selectedMenu [页面标题, ...对应的menuItem key]
 * 有时一个页面可以对应多个菜单，在跳转时url params拼上 ?p2m=index
 * 表示selectedMenu第index menuItem被选中
 * dispatch({
 *   type: 'page2menu/update'
 *   payload: { pathname: '/some/path', selectedMenu: ['页面标题', 'menuItem key', 'menuItem key2'] }
 * })
 */

const data = {
  // '/some/path': ['页面标题', 'menuItem key', 'menuItem key2'],
  '/example-page2menu': ['page2menu example', '/home'],
}

export default data;
