import request from '@/utils/request';

// 获取项目列表
export async function getProjects() {
  return request('/snake-tool/api-docs/project-names');
}

// 获取项目模块列表
export async function getSubprojects(params: any) {
  if (!params.projectValue) {
    return;
  }
  return request(`/snake-tool/api-docs/projects/${params.projectValue}/subproject-names`, {
    params,
  });
}


// 更新接口文档列表
export async function updateApidocsList(params: any) {
  return request(`/snake-tool/api-docs`, {
    method: 'POST',
    params,
  });
}


// 获取接口文档列表
export async function getApidocsList(params: any) {
  return request(`/snake-tool/api-docs`, {
    params,
  });
}


// 获取接口文档详情
export async function getApidocsDetail(params: any) {
  return request(`/snake-tool/api-docs/${params.id}`, {
    params,
  });
}


// 获取接口差异
export async function getApidiffsList(params: any) {
  return request(`/snake-tool/api-diffs`, {
    params,
  });
}

// 获取接口差异详情
export async function getApidiffsDetail(params: any) {
  return request(`/snake-tool/api-diffs/${params.id}`, {
    params,
  });
}

// 获取接口差异详情
export async function getApidiffsDiff(params: any) {
  return request(`/snake-tool/api-diffs/diff`, {
    params,
  });
}