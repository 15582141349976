import { AnyAction, Reducer } from 'redux';
import { EffectsCommandMap } from 'dva';

import {
  getGroup,
  addGroup,
  updateGroup,
  delGroup,
  addMembersToGroup,
  getGroupDetails,
  delMembersToGroup,
  getStudentByTel,
  
} from '@/services/custom';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { PaginationProps } from 'antd/lib/pagination';

export interface StateType<T=any> {
  visibleModal: boolean
  actionRecord: {
    [key: string]: any
  }
  groupList: {
    list: T[]
    pagination: PaginationProps
  }
  visibleGroupPersonModal: boolean | ''
  groupDetail: {
    studentGroupVos: any[]
    teacherGroupVos: any[]
    [key: string]: any
  }
  isShowStudentsModal: boolean
  phoneData: T[]
  is_show_group_list: boolean
  is_show_add_student: boolean
  groupParams: object
  groupType: string
}
export type Effect = (
  action: AnyAction,
  effects: EffectsCommandMap & { select: <T>(func: (state: StateType) => T) => T },
) => void;

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    addGroup: Effect
    updateGroup: Effect
    delGroup: Effect
    getGroup: Effect
    addMembersToGroup: Effect
    getGroupDetails: Effect
    delMembersToGroup: Effect
    getStudentByTel: Effect
   
  };
  reducers: {
    updateState: Reducer<StateType>;
  };
}

export interface CustomProps {
  dispatch: Dispatch
  custom: StateType
  loading: {
    effects: {
      [key: string]: boolean;
    }
  }
}

const Model: ModelType = {
  namespace: 'custom',

  state: {
    visibleModal: false,
    actionRecord: {},
    groupList: {
      list: [],
      pagination: {}
    },
    visibleGroupPersonModal: false,
    isShowStudentsModal: false,
    groupDetail: {
      studentGroupVos: [],
      teacherGroupVos: []
    },
    phoneData: [],
    is_show_group_list: true,
    is_show_add_student: false,
    groupParams: {},
    groupType: 'STUDENT'
  },

  effects: {
    *getStudentByTel({ payload = {}, callback }, { call, put }) {
      try{
        const result = yield call(getStudentByTel, payload);
        let response = {
          data: result.data,
          httpStatus: result.response.status,
        }
        if (response.httpStatus === 200 && _.isArray(response.data)) {
          yield put({
            type: 'updateState',
            payload: {
              phoneData: response.data
            }
          });
        }
      }catch(e){}
    },
    *delMembersToGroup({ payload = {}, callback }, { call, put }){
      try {
        const result = yield call(delMembersToGroup, payload);
        let response = {
          httpStatus: result.response.status,
        }
        if (callback) { callback(response.httpStatus === 200 ? 'success' : 'error') }
        
      } catch (e) {
      }
    },
    *getGroupDetails({ payload = {}, callback }, { call, put }){
      try{
        const result = yield call(getGroupDetails, payload);
        let response = {
          httpStatus: result.response.status
        }
        if (response.httpStatus === 200) {
          yield put({
            type: 'updateState',
            payload: {
              groupDetail: _.get(result,'data'),
             
            }
          })
        }
        
      }catch(e){}
    },
    *addMembersToGroup({ payload = {}, callback }, { call, put }) {
      try{
        const result = yield call(addMembersToGroup, payload);
        let response = {
          httpStatus: result.response.status
        }
        if (callback) { callback(response.httpStatus === 200 ? 'success' : 'error') }
      }catch(e){}
    },
    *getGroup({ payload, callback }, { call, put, select }){
      try {
        payload.pageSize = payload.pageSize || 10
        const result = yield call(getGroup, payload);
        const { groupList } = yield select(state => state.custom)
        let response = { ...result.data, httpStatus: result.response.status }
        
        if (response.httpStatus === 200 && _.isArray(response.records)) {
          let list =  _.get(response,'records',[])
          _.each(list, item => {
            item._type = 'CUSTOM_USER_GROUP'
            
          })

          
          yield put({
            type: 'updateState',
            payload: {
              groupList: {
                list,
                pagination: {
                  showTotal: (total: number) => `共 ${response.total} 条`,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  current: _.get(response, 'currentPage'),
                  total: _.get(response, 'total'),
                  pageSize: _.get(response, 'pageSize'),
                }
              },
              groupParams: {
                ...payload
              }
            }
          })
        }
      } catch (e) {
      }
    },
    *delGroup({ payload, callback }, { call, put }){
      try {
       
        const result = yield call(delGroup, payload);
        let response = {
          httpStatus: result.response.status,
        }
        if (callback) { callback(response.httpStatus === 204 ? 'success' : 'error') }
        
      } catch (e) {
      }
    },
    *updateGroup({ payload, callback }, { call, put }){
      try {
        
        const result = yield call(updateGroup, payload);
        let response = {
          httpStatus: result.response.status,
        }
        if (callback) { callback(response.httpStatus === 200 ? 'success' : 'error') }
        
      } catch (e) {
      }
    },
    *addGroup({ payload, callback }, { call, put }){
      try {
       
        const result = yield call(addGroup, payload);
        let response = {
          httpStatus: result.response.status,
        }
        if (callback) { callback(response.httpStatus === 201 ? 'success' : 'error') }
        
      } catch (e) {
      }
      
    }
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;

