import request from '@/utils/request'

export async function deleteStudent(params: any) {
  return request(`/school/semester-klass-students/${params.id}`, {
    method: 'DELETE',
    data: params
  })
}

export async function getSemesterList(params: any) {
  return request(`/school/semesters`, {
    params,
  });
}

export async function getSemesterClassList(params: any) {
  return request('/school/semester-klasses', {
    params,
  });
}
//退学
export async function leaveSchool(params: any) {
  return request(`/school/semester-klass-students/${params.id}`, {
    method: 'DELETE',
    data: params,
    getResponse: true
  })
}
//调班
export async function changeKlass(params: any) {
  return request(`/school/semester-klass-students/${params.id}/change-klass`, {
    method: 'POST',
    data: params
  })
}

// 根据学生id添加家长
export async function addParent(params: any) {
  return request(
    `/account/students/${params.studentId}/add-parent`, {
    method: 'POST',
    data: params
  }
  )
}

// 根据家长或孩子id查询关系
export async function getParentInformation({ student_id = '', parent_id = '' } = {}) {
  let url = `/account/parent-students?studentId=${student_id}&parentId=${parent_id}`
  return request(url, {
    method: 'GET'
  })
}

// 设置主家长
export async function setMainParent(params: any) {
  return request(`/account/students/${params.studentId}/set-main-parent`, {
    method: 'POST',
    data: params
  })
}

export async function childMerge(params: any) {
  return request(`/account/children/merge`, {
    method: 'POST',
    data: params
  })
}
