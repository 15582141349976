import _ from 'lodash'
import request from '@/utils/request';
import { getAccessToken, isAdmin, getUserStorage } from '@/utils/utils'

export async function getSchoolList(params: any) {
  return request(`/school/schools`,{
    params
  });
}

export async function logout(params: any) {
  return request(`/oauth/revoke-token`, {
    method: 'POST',
    data: {
      accessToken: getAccessToken()
    },
  });
}

// 添加用户
export async function createUser(params: any) {
  return request(`/account/users`, {
    method: 'POST',
    data: params,
  });
}

export async function getStudentByParentMobile(params:any){
  return request(`/account/users/students-by-parent-mobile/${params.parentMobile}`)
}

export async function getUserByIdCard(params:any){
  return request(`/account/users/by-id-card/${params.idCard}`)
}

export async function getWechatGroup(params: any) {
  return request(`/wechat/groups`,{
    params
  });
}

// 获取学期年级班级树形菜单
export async function getSemesterTree(params: any) {
  const user = getUserStorage() || {}
  const currentSchool = _.find(user.schoolList,school => school.schoolId == user.schoolId) || {}
  return request(`/school/schools/${params.id}/semester-tree`,{
    params: { ...params, fullData: !!(isAdmin() || _.get(currentSchool,'isAdmin'))}
  });
}
// 学校列表的操作接口headers要带上r-current-school-id
export async function addAdminToSchool(params: any) {
  return request(`/snake-biz/school-teachers`, {
    method: 'POST',
    data: params,
    getResponse: true,
    headers: {
      'r-current-school-id': params.schoolId
    }
  });
}

export async function setSchoolAdmin(params:any){
  return request(`/school/school-teachers/set-admin`,{
    method:'POST',
    data:params
  })
}