import request from '@/utils/request';

export async function send(params: any) {
  return request(`/interaction-v2/group-messages`, {
    method: 'POST',
    data: params,
  });
}

export async function getSemesterClassStudents(params: any) {
  return request(`/school/semester-klass-students`, {
    params,
  });
}

export async function getTeacherList(params: any) {
  return request(`/school/school-teachers`, {
    params,
  });
}

export async function getGroupTree(params: any) {
  return request('/interaction-v2/user-groups/group-and-members', {
    method: 'GET',
    params
  });
}

export async function getDepartTree(params: any) {
  return request(`/account/departments/tree`,{ 
    method: 'GET'
  });
}

export async function getGroup(params: any) {
  return request('/interaction-v2/user-groups', {
    params,
  });
}