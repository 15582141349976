/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { extend } from 'umi-request';
import { message, notification } from 'antd';
import _ from 'lodash'
import { getAccessToken, getSchoolId } from "@/utils/utils";
import { routerRedux } from 'dva/router';
import Cookies from 'js-cookie'

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/**
 * 异常处理程序
 */
const errorHandler = (error: { response: Response }) => {
  const { response } = error;
  let messages = '', description = ''
  // console.log('异常处理111111111', error)
  // console.log('异常处理keys',Object.keys(error))
  // console.log('异常处理name',error.name)
  // console.log('异常处理222222222',error.data)
  // console.log('异常处理333333333',response)

  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    if (error.data && (error.data.extraMessage || error.data.message)) {
      messages = error.data.extraMessage || error.data.message
      description = ''
    } else {
      messages = `请求错误 ${status}: ${url}`
      description = errorText
    }

    // current-user获取菜单接口出错时跳转到登录
    if(_.get(error, "response.url").indexOf('/sys/menus/current-user') != -1){
      Cookies.remove('accessToken');
      localStorage.clear();
      window.g_app._store.dispatch(
        routerRedux.replace({
          pathname: '/user/login',
        })
      )
    }

    if (_.get(error, 'data.code') === "310004" && _.get(error, "response.url").indexOf('/oauth/token') != -1) {
      message.error("用户未初始化密码，正为你跳转至初始化密码页面")
      setTimeout(() => {
        window.g_app._store.dispatch(
        routerRedux.push({
          pathname: '/user/init-password',
          query: {
            init_password: true
          }
        }))
      }, 2000);
    } else {
      notification.error({
        message: messages,
        description
      });
      // console.log(error.data.code)
      // if(_.get(error,'data.code') === '320011'){
      //  //考勤实时统计抛错不提示，页面处理
      // } else {

      // }
    }
  }
  throw error
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
  getResponse: true, // 获取源响应
});

request.use(async (ctx, next) => {
  const { req } = ctx;
  const { url, options } = req;

  const paramsSchoolId = _.get(ctx,'req.options.params.schoolId',0) ||
  _.get(ctx,'req.options.data.schoolId',0)
  let querySchoolId = _.get(window, 'g_history.location.query.schoolId', 0);
  // console.log('ctx.req.options.params',ctx,paramsSchoolId)
  // console.log('request.use ctx',ctx)
  //
  const defaultHeaderSchoolId = _.get(ctx,'req.options.headers.r-current-school-id')
  // console.log('defaultHeaderSchoolId',defaultHeaderSchoolId)
  // console.log('querySchoolId',querySchoolId)
  if (
    url != '/oauth/token'
    && url != '/account/userinfo'
    && url != '/account/password/send-reset-sms'
    && url != '/account/password/reset-by-sms'
  ) {
    // 获取用户信息时使用最新的access_token 不能从getAccessToken()获取
    options.headers['Authorization'] = 'Bearer ' + getAccessToken()

    // 学校列表添加管理员特殊处理
    _.isUndefined(defaultHeaderSchoolId) &&
    (options.headers['r-current-school-id'] = querySchoolId || paramsSchoolId || getSchoolId())
  }
  if (
    url.indexOf('/school/attendances/live/count') !=  -1
  ) {
    // 获取用户信息时使用最新的access_token 不能从getAccessToken()获取
    options.headers['Authorization'] = 'Bearer ' + getAccessToken()
    options.headers['r-current-school-id'] = querySchoolId || paramsSchoolId || getSchoolId()
    options.headers['groupTargetId'] = querySchoolId || paramsSchoolId || getSchoolId()
  }

  const { res } = ctx;
  await next();
})
request.interceptors.response.use((response, options) => {
  if (response.status != 200 && response.status != 201 && response.status != 204 && response.status != 401) {
    return response;
  } else if (response.status == 401 && response.url.indexOf('/account/password/send-reset-sms') == -1) {
    Cookies.remove('accessToken');
    localStorage.clear();
    setTimeout(function () { window.g_app._store.dispatch(routerRedux.replace(`/user/login`)) }, 1000)
  } else if (response.status == 403) {
    localStorage.clear();
    Cookies.remove('accessToken');
    setTimeout(function () { window.g_app._store.dispatch(routerRedux.replace(`/user/login`)) }, 1000)
    return;
  } else {
    return response;
  }
});

export default request;

export async function downloadFile(url, name) {
  console.log('downloadfile',name)
  try{
    const result = await request.get(url,{
      responseType: 'blob',
      getResponse: true,
      errorHandler: (error) => {
        error.response.json()
        .then(res=>{
          notification.error({
            message: res.extraMessage || res.message
          });
          console.log('response rs',res)
        })
        throw error
      }
    })

    const { data: blob, response } = result
    // console.log('new Blob',new Blob(['\uFEFF' + content]))
    if(!response){
      return
    }
    if(!response.headers.get("content-disposition")){
      return
    }

    const headerDisposition = response.headers.get("content-disposition") || ''
    const getFilenameIndex = headerDisposition.indexOf('filename=')
    name = response.headers.get("content-disposition").slice(getFilenameIndex + 'filename='.length)
    // console.log({headerDisposition,getFilenameIndex,name})
    // 'student-export-阳光中学-1597989594851.xls'
    let excelName = name.replace(/\d{13}/,function(date){ return new Date(Number(date)).toLocaleDateString()})
    // console.log('excelName',excelName)
    /*
      decodeURIComponent(escape(name)) 可解决中文乱码问题
      废弃的 escape(), 须后端处理，不能出现中文
    */
    excelName =  decodeURIComponent(excelName)
    const aEle = document.createElement('a')
    aEle.textContent = `Download(${excelName})`;

    aEle.href = URL.createObjectURL(blob);
    aEle.setAttribute('style', 'display: none')
    aEle.download = excelName;
    aEle.click()
    setTimeout(() => {
      if (aEle.parentNode) {
        aEle.parentNode.removeChild(aEle);
      }
    }, 100)
  }catch(e){}
}
