import request from '@/utils/request';

export async function getStudentByTel(params: any) {
  return request(`/interaction-v2/user-groups/get-students-by-mobile/${params.mobile}`, {

  });
}

export async function getGroupDetails(params: any) {
  return request(`/interaction-v2/user-groups/${params.groupId}`);
}

export async function addMembersToGroup(params: any) {
  return request(`/interaction-v2/user-groups/${params.groupId}/add-members`, {
    method: 'POST',
    data: params,
  });
}

export async function delMembersToGroup(params: any){
  return request(`/interaction-v2/user-groups/${params.groupId}/delete-members`,{
    method: 'DELETE',
    data: params,
    getResponse:true
  })
}

export async function getGroup(params: any) {
  return request('/interaction-v2/user-groups', {
    params,
  });
}

export async function addGroup(params: any) {
  return request(`/interaction-v2/user-groups`,{
    method: 'POST',
    data: params
  });
}

export async function updateGroup(params: any){
  return request(`/interaction-v2/user-groups/${params.id}`,{
    method: 'PUT',
    data: params,
    getResponse:true
  })
}

export async function delGroup(params: any){
  return request(`/interaction-v2/user-groups/${params.id}`,{
    method: 'DELETE',
    getResponse:true
  })
}