import { Effect, EffectsCommandMap } from 'dva';
import defaultData from '../../config/page2menu';

type selectedMenuType = string[]
type page2menuDataType = {
  [index: string]: selectedMenuType
}
export interface Page2menuModelState {
  data: page2menuDataType;
}

/**
 * 需在跳转页面前注册页面到菜单的关系
 *  - 直接写进state data （大部分情况下）
 *    state: {
        data: {
          '/some/path': ['页面标题', 'menuItem key', 'menuItem key2'],
        },
      }
 *  - 可以在程序初始化时
 *  - 在跳转前
 *  - 跳转后再次注册用于动态标题
 * selectedMenu [页面标题, ...对应的menuItem key]
 * 有时一个页面可以对应多个菜单，在跳转时url params拼上 ?p2m=index
 * 表示selectedMenu第index menuItem被选中
 * dispatch({
 *   type: 'page2menu/update'
 *   payload: { pathname: '/some/path', selectedMenu: ['页面标题', 'menuItem key', 'menuItem key2'] }
 * })
 */
export interface Page2menuModelType {
  namespace: 'page2menu';
  state: Page2menuModelState;
  effects: {
    update: (
      action: { payload: { pathname: string; selectedMenu: selectedMenuType } },
      effects: EffectsCommandMap,
    ) => void;
    remove: (
      action: { payload: { pathname: string; } },
      effects: EffectsCommandMap,
    ) => void;
  };
  reducers: {
    updateState: Effect;
  }
}

const Page2menuModel: Page2menuModelType = {
  namespace: 'page2menu',
  state: {
    data: {
      // '/some/path': ['页面标题', 'menuItem key', 'menuItem key2'],
      ...defaultData,
    },
  },
  effects: {
    *update({ payload }, { select, put }) {
      const { data } = yield select((state: { page2menu: Page2menuModelType }) => state.page2menu);
      yield put({
        type: 'updateState',
        payload: {
          data: {
            ...data,
            [payload.pathname]: payload.selectedMenu,
          },
        },
      });
    },
    *remove({ payload }, { select, put }){
      const { data } = yield select((state: { page2menu: Page2menuModelType }) => state.page2menu);
      const tempData = { ...data };
      delete tempData[payload.pathname]
      yield put({
        type: 'updateState',
        payload: {
          data: {
            ...tempData,
          },
        },
      });
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
}

export default Page2menuModel
