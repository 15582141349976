import { AnyAction, Reducer } from 'redux';
import { EffectsCommandMap } from 'dva';

import {
  getSemesterClassStudents,
  getTeacherList,
  getGroupTree,
  getDepartTree,
  send,
  getGroup
} from '@/services/mschool'
import { getSemesterTree } from '@/services/global';
import _ from 'lodash';
import { Dispatch } from 'redux';

import * as d3 from 'd3-hierarchy';
import moment, { Moment } from 'moment'
import { PaginationProps } from 'antd/lib/pagination';

import { enumDateTime } from '@/utils/utils'

export enum Receivers {
  SEMESTER_PARENT, 
  SEMESTER_CLASS_PARENT, 
  CUSTOM_USER_GROUP, 
  SPECIAL_PARENT, 
  SPECIAL_TEACHER, 
  DEP_TEACHER
}

export const receivers = [
  'SEMESTER_PARENT', 
  'SEMESTER_CLASS_PARENT', 
  'CUSTOM_USER_GROUP',
  'SPECIAL_PARENT', 
  'SPECIAL_TEACHER', 
  'DEP_TEACHER'
]

export enum MessageType {
  NOTIFICATION,
  HOMEWORK
}

interface IReceiversItem {
  type: string
  ids: string
  names: string
}

export interface FormDataItem {
  type: string
  countSize: number
  title: string
  content: string
  sendPattern: 'INSTANT_TRANSMISSION' | 'SCHEDULED_TRANSMISSION'
  is_disabled: boolean
  scheduledTime: Moment | string
}

export interface StateType<T=any> {
  recipientModalVisible: boolean | string
  sentActionRecord: {
    [key: string]: any
  }
  groupTree: T[]
  treeData: Array<T>;
  departTree: object;
  sendTimingType: string
  activeKey: string
  payloadParams: {
    [key: string]: any
  }
  classStudentList: {
    list: T[],
    pagination: PaginationProps
  }

  teacherListParams: Object
  teacherList: {
    list: T[],
    pagination: PaginationProps
  }
  teacherPayloadParams: {
    [key: string]: any
  }

  is_show_select: boolean
  is_show_receive: boolean
  //年级班级 
  checkedKlassIds: T[]
  checkedGradeIds: T[]
  checkedKlassNames: T[]
  checkedGradeNames: T[]
  checkedGradeList: T[],
  checkedKlassList: T[],
  gradeAndKlassList: T[]
  checkAllGrade: boolean
  // 自定义组
  customGroupList: T[]
  teacherCustomList: T[]
  checkAllCustom: boolean
  // 特定学生
  checkedStudentList: T[],  //选中的项，对象数组
  // 教师部门
  checkedDepartList: T[]
  checkedAllDepart: boolean
  // 特定教师
  checkedTeacherList: T[]

  receivers: IReceiversItem[]

  groupList: {
    list: T[]
    pagination: PaginationProps
  }

  groupParams: object
  groupType: string
  
  formDataList: FormDataItem[]
}
export type Effect = (
  action: AnyAction,
  effects: EffectsCommandMap & { select: <T>(func: (state: StateType) => T) => T },
) => void;

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    getSemesterClassStudents: Effect
    getTeacherList: Effect
    getGradeKlassTree: Effect
    getGroupTree: Effect
    getDepartTree: Effect
    resetAll: Effect
    send: Effect
    getGroup: Effect
  };
  reducers: {
    updateState: Reducer<StateType>;
  };
}

export interface MschoolProps {
  dispatch: Dispatch
  mschool: StateType
}

export const defaultReceivers = _.map(receivers, item => ({ type: item, ids: '', names: ''}))

const dateFormat = 'YYYY-MM-DD HH:mm';

export const currentDate = () => moment(new Date()).format(dateFormat)


export const defaultFormDataList = (): FormDataItem[] => [
  { 
    type: 'elder',
    countSize: 0,
    title: `${moment().format('YYYY-MM-DD')}通知`,
    content: '',
    sendPattern: 'INSTANT_TRANSMISSION',
    is_disabled: true, 
    scheduledTime: currentDate()
  },
  { 
    type: 'teacher',
    countSize: 0,
    title: `${moment().format('YYYY-MM-DD')}通知`,
    content: '',
    sendPattern: 'INSTANT_TRANSMISSION',
    is_disabled: true, 
    scheduledTime: currentDate()
  },
] 

const Model: ModelType = {
  namespace: 'mschool',

  state: {
    recipientModalVisible: false,
    sentActionRecord: {},
    groupTree: [],
    treeData: [],
    departTree: {},
    sendTimingType: 'elder',
    activeKey: 'grade',
    classStudentList: {
      list: [],
      pagination: {}
    },
    payloadParams: {},

    teacherList: {
      list: [],
      pagination: {}
    },
    teacherPayloadParams: {},
    teacherListParams: {},

    is_show_select: true,
    is_show_receive: false,

    //选择接收人年级班级ids
    checkedGradeIds: [],
    checkedGradeNames: [],
    checkedKlassIds: [],  
    checkedKlassNames: [],
    checkedGradeList: [],
    checkedKlassList: [],
    gradeAndKlassList: [],
    checkAllGrade: false,
    // 自定义组
    customGroupList: [],
    teacherCustomList: [],
    checkAllCustom: false,
    // 特定学生
    checkedStudentList: [],  
    // 部门
    checkedDepartList: [],
    checkedAllDepart: false,
    // 特定教师
    checkedTeacherList: [],

    receivers: defaultReceivers,
    
    groupList: {
      list: [],
      pagination: {}
    },
    groupParams: {},
    groupType: 'STUDENT',
    
    formDataList: defaultFormDataList()
  },

  effects: {
    *send({ payload, callback }, { call, put }){
      try {
        const result = yield call(send, payload);
        let response = { ...result.data, httpStatus: result.response.status }
        
        if (callback) { callback(response.httpStatus === 200 ? 'success' : 'error') }
      } catch (e) { }
    },
    *getSemesterClassStudents({ payload }, { call, put, select }) {
      try {
        payload.pageSize = payload.pageSize || 10
        payload = _.pickBy(payload,_.identity)
        const result = yield call(getSemesterClassStudents, payload);
        const { classStudentList } = yield select(state => state.mschool)
        console.log('select',classStudentList)
        let response = { ...result.data, httpStatus: result.response.status }
        let list: any[] = _.get(response, 'records', [])
        enumDateTime(list)

        _.each(list, item => {
          item._type = 'SPECIAL_PARENT'
          item.key = `SPECIAL_PARENT-${item.id}`
          item.title = item.studentName
          item.name = item.studentName
        })

        yield put({
          type: 'updateState',
          payload: {
            classStudentList: {
              list,
              pagination: {
                showTotal: (total: number) => `共 ${_.get(response,'total',0)} 条`,
                showQuickJumper: true,
                showSizeChanger: true,
                current: _.get(response, 'currentPage'),
                total: _.get(response, 'total'),
                pageSize: _.get(response, 'pageSize'),
              }
            },
            payloadParams: {
              ...payload
            }
          }
        });
      } catch (e) { }
    },
    *getGradeKlassTree({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getSemesterTree, payload);
        let response = {
          httpStatus: result.response.status,
        }
        
        if (response.httpStatus === 200 && _.isArray(result.data)) {
          _.each(result.data, grade => {
            grade.key = `semesterId-${grade.semesterId}`
            grade.title = grade.semesterName
            grade.id = grade.semesterId
            grade.name = grade.semesterName
            grade._type = 'SEMESTER_PARENT'
            if(grade.children){
              _.each(grade.children, klass => {
                klass.key = `semesterKlassId-${klass.semesterKlassId}`
                klass.title = klass.semesterKlassName
                klass.id = klass.semesterKlassId
                klass.name = klass.semesterKlassName
                klass._type = 'SEMESTER_CLASS_PARENT'
              })
            }
          })
          yield put({
            type: 'updateState',
            payload: {
              treeData: result.data,
            }
          });
        }
      } catch (e) {
      }
    },
    *getGroupTree({ payload, callback },{ call, put }){
      try{
        const result = yield call(getGroupTree, payload)
        let response = {
          data: result.data,
          httpStatus: result.response.status,
        }
        if (response.httpStatus === 200 && response.data) {
          let list = _.get(result,'data',[])
          _.each(list, item => {
            item._type = 'CUSTOM_USER_GROUP'
            
          })
          console.log('groupTree',result)
          yield put({
            type: 'updateState',
            payload: {
              groupTree: list
            }
          });
        }
      }catch(e){}
    },
    *getDepartTree({ payload = {} }, { call, put, select }){
      try{
        const { currentUser } = yield select(state => state.user) 
        const result = yield call(getDepartTree, payload);
        const rootData = {
          schoolId: _.get(currentUser,'schoolId',0),
          schoolName: _.get(currentUser,'schoolName','')
        }
        
        rootData.children =  result.data
        console.log('result',result,rootData)
        let departTree = d3.hierarchy(rootData, function children(d) {
          return d.children;
        })
        departTree.each(d => {
          
          d.key = `${_.get(d.data,'id','')}` || `${_.get(d.data,'schoolId','')}`
          d.value = `${_.get(d.data,'id','')}` || `${_.get(d.data,'schoolId','')}`
          d.title =`${_.get(d.data,'name','')}` || _.get(d.data,'schoolName')
          d.name=`${_.get(d.data,'name','')}` || _.get(d.data,'schoolName')
          d._type = 'DEP_TEACHER'
        });
        yield put({
          type: 'updateState',
          payload: {
            departTree: departTree,
          }
        })
  
      }catch(e){}
    },
    *getTeacherList({ payload = {} }, { call, put, select }) {
      try {
        payload.pageSize = payload.pageSize || 10
        payload = _.pickBy(payload,_.identity)
        const result = yield call(getTeacherList, payload);
        const { teacherList } = yield select(state => state.mschool)
        let response = { ...result.data, httpStatus: result.response.status }
        
        if (response.httpStatus === 200 && _.isArray(response.records)) {
          let list = response.records
          enumDateTime(list)

          _.each(list, item => {
            item._type = 'SPECIAL_TEACHER'
            item.key = `SPECIAL_TEACHER-${item.id}`
            item.title = item.teacherName
            item.name = item.teacherName
          })

          yield put({
            type: 'updateState',
            payload: {
              teacherList: {
                list,
                pagination: {
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total: number) => `共 ${_.get(response,'total',0)} 条`,
                  current: _.get(response, 'currentPage',1),
                  total: _.get(response, 'total',0),
                  pageSize: _.get(response, 'pageSize',10),
                }
              },
              teacherPayloadParams: {
                ...payload
              }
            }
          });
        }
      } catch (e) { }
    },
    *getGroup({ payload, callback }, { call, put, select }){
      try {
        payload.pageSize = payload.pageSize || 10
        const result = yield call(getGroup, payload);
       
        let response = { ...result.data, httpStatus: result.response.status }
        
        if (response.httpStatus === 200 && _.isArray(response.records)) {
          let list =  _.get(response,'records',[])
          _.each(list, item => {
            item._type = 'CUSTOM_USER_GROUP'
            item.key = `CUSTOM_USER_GROUP-${item.id}`
          })
          yield put({
            type: 'updateState',
            payload: {
              groupList: {
                list,
                pagination: {
                  showTotal: (total: number) => `共 ${response.total} 条`,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  current: _.get(response, 'currentPage'),
                  total: _.get(response, 'total'),
                  pageSize: _.get(response, 'pageSize'),
                }
              },
              groupParams: {
                ...payload
              }
            }
          })
        }
      } catch (e) {
      }
    },
    *resetAll({ payload={}, callback },{ call, put }){
      yield put({
        type: 'updateState',
        payload: {
          // 年级班级
          checkedGradeIds: [],
          checkedGradeNames: [],
          checkedKlassIds: [],  
          checkedKlassName: [],
          checkedGradeList: [],
          checkedKlassList: [],
          gradeAndKlassList: [],
          checkAllGrade: false,
          // 自定义组
          customGroupList: [],
          teacherCustomList: [],
          checkAllCustom: false,
          // 特定学生
          checkedStudentList: [], 
          // 部门
          checkedDepartList: [],
          checkedAllDepart: false,
          // 特定教师
          checkedTeacherList: [],
          receivers: defaultReceivers,
          content: ''
        }
      })
    },
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;

