import request from '@/utils/request';

export async function getUserByMobile(params: any) {
  return request(`/snake-biz/users/school-teacher/by-mobile/${params.mobile}`);
}

export async function query(): Promise<any> {
  return request('/api/users');
}

export async function queryCurrent(): Promise<any> {
  // return request('/api/currentUser');
}

export async function queryNotices(): Promise<any> {
  return request('/api/notices');
}

// 修改登录密码
export async function updateUpwd(params: any): Promise<any> {
  return request(`/account/users/${params.id}/reset-password`,{
    method: 'POST',
    data: params
  })
}
