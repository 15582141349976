import { AnyAction, Reducer } from 'redux';
import { EffectsCommandMap, Subscription } from 'dva';
import { Dispatch } from 'redux';
import _ from 'lodash';
import {
  getSchoolOrdersCount,
} from '@/services/ordersCount';
export interface OrdersCountState<T = any> {
  schoolOrdersCount: T[]
}
export type Effect = (
  action: AnyAction,
  effects: EffectsCommandMap & { select: <T>(func: (state: OrdersCountState) => T) => T },
) => void;
export interface ModelType {
  namespace: string;
  state: OrdersCountState;
  effects: {
    getSchoolOrdersCount: Effect
  };
  reducers: {
    updateState: Reducer<OrdersCountState>;
  };
  subscriptions: { setup: Subscription };
}
export interface OrdersCountProps {
  dispatch: Dispatch;
  ordersCount: OrdersCountState
}
const Model: ModelType = {
  namespace: 'ordersCount',
  state: {
    schoolOrdersCount: [
      // {studentCount: 1000,orderedStudentCount: 600,orderedOrderCount: 500}
    ],
    
  },
  subscriptions: {
    setup({ dispatch, history }): void {
      history.listen(({ pathname }): void => {
      });
    },
  },

  effects: {
    *getSchoolOrdersCount({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getSchoolOrdersCount, payload);
        let response = { ...result.data, httpStatus: result.response.status }
        
        if (response.httpStatus === 200) {
          yield put({
            type: 'updateState',
            payload: {
              schoolOrdersCount: result.data || []
            }
          });
        }
        if (callback) callback();
      } catch (e) { }
    },
   
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
export default Model;
