import React from 'react';
import { Router as DefaultRouter, Route, Switch } from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@tmp/history';
import RendererWrapper0 from '/usr/src/app/src/pages/.umi-production/LocaleWrapper.jsx';
import RendererWrapper1 from './sentry/index';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/external-link',
        routes: [
          {
            name: 'api-detail',
            path: '/external-link/api-detail',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__external-link__api-detail" */ '../external-link/api-detail'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../external-link/api-detail').default,
            exact: true,
          },
          {
            name: 'diff-detail',
            path: '/external-link/diff-detail',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__external-link__diff-detail" */ '../external-link/diff-detail'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../external-link/diff-detail').default,
            exact: true,
          },
        ],
      },
      {
        path: '/user',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/UserLayout').default,
        routes: [
          {
            path: '/user',
            redirect: '/user/login',
            exact: true,
          },
          {
            name: 'login',
            path: '/user/login',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__user__login__model.ts' */ '/usr/src/app/src/pages/user/login/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__user__login" */ '../user/login'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../user/login').default,
            routes: [
              {
                name: 'admin',
                path: '/user/login/admin',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__user__login__model.ts' */ '/usr/src/app/src/pages/user/login/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "p__user__login" */ '../user/login'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../user/login').default,
                exact: true,
              },
            ],
          },
          {
            name: 'init-password',
            path: '/user/init-password',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__user__init-password__model.ts' */ '/usr/src/app/src/pages/user/init-password/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__user__init-password" */ '../user/init-password'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../user/init-password').default,
            exact: true,
          },
          {
            name: 'register-result',
            path: '/user/register-result',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__user__register-result" */ '../user/register-result'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../user/register-result').default,
            exact: true,
          },
          {
            name: 'register',
            path: '/user/register',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__user__register__model.ts' */ '/usr/src/app/src/pages/user/register/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__user__register" */ '../user/register'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../user/register').default,
            exact: true,
          },
          {
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__404" */ '../404'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../404').default,
            exact: true,
          },
        ],
      },
      {
        path: '/admin',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/UserLayout').default,
        routes: [
          {
            path: '/admin',
            redirect: '/user/login/admin',
            exact: true,
          },
        ],
      },
      {
        path: '/',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/BasicLayout').default,
        Routes: [require('../Authorized').default],
        routes: [
          {
            path: '/home',
            name: 'home',
            icon: 'home',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__home__model.ts' */ '/usr/src/app/src/pages/home/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__home" */ '../home'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../home').default,
            exact: true,
          },
          {
            path: '/business',
            name: 'business',
            icon: 'user',
            authority: ['admin'],
            routes: [
              {
                name: 'classification',
                path: '/business/classification',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__business__classification__model.ts' */ '/usr/src/app/src/pages/business/classification/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../business/classification'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../business/classification').default,
                exact: true,
              },
              {
                name: 'administration',
                path: '/business/administration',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__business__administration__model.ts' */ '/usr/src/app/src/pages/business/administration/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../business/administration'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../business/administration').default,
                exact: true,
              },
              {
                name: 'administration-details',
                path: '/business/administration-details',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__business__administration-details__model.ts' */ '/usr/src/app/src/pages/business/administration-details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../business/administration-details'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../business/administration-details').default,
                exact: true,
              },
              {
                name: 'brand',
                path: '/business/brand',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__business__brand__model.ts' */ '/usr/src/app/src/pages/business/brand/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../business/brand'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../business/brand').default,
                exact: true,
              },
              {
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../404'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../404').default,
                exact: true,
              },
            ],
          },
          {
            path: '/school-management',
            name: 'school-management',
            icon: 'user',
            routes: [
              {
                name: 'course',
                path: '/school-management/course',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__school-management__course__model.ts' */ '/usr/src/app/src/pages/school-management/course/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../school-management/course'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../school-management/course').default,
                exact: true,
              },
              {
                name: 'grade',
                path: '/school-management/grade',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__school-management__grade__model.ts' */ '/usr/src/app/src/pages/school-management/grade/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../school-management/grade'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../school-management/grade').default,
                exact: true,
              },
              {
                name: 'create-school',
                path: '/school-management/create-school',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__school-management__create-school__model.ts' */ '/usr/src/app/src/pages/school-management/create-school/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../school-management/create-school'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../school-management/create-school').default,
                exact: true,
              },
              {
                name: 'basic-information',
                path: '/school-management/basic-information',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__school-management__basic-information__model.ts' */ '/usr/src/app/src/pages/school-management/basic-information/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../school-management/basic-information'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../school-management/basic-information').default,
                exact: true,
              },
              {
                name: 'school-detail',
                path: '/school-management/school-detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__school-management__school-detail__model.ts' */ '/usr/src/app/src/pages/school-management/school-detail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../school-management/school-detail'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../school-management/school-detail').default,
                exact: true,
              },
              {
                name: 'class-management',
                path: '/school-management/class-management',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__school-management__class-management__model.ts' */ '/usr/src/app/src/pages/school-management/class-management/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../school-management/class-management'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../school-management/class-management').default,
                exact: true,
              },
              {
                name: 'report-notice',
                path: '/school-management/report-notice',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__school-management__report-notice__model.ts' */ '/usr/src/app/src/pages/school-management/report-notice/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../school-management/report-notice'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../school-management/report-notice').default,
                exact: true,
              },
              {
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../404'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../404').default,
                exact: true,
              },
            ],
          },
          {
            path: '/semester-management',
            name: 'semester-management',
            icon: 'usergroup-add',
            routes: [
              {
                name: 'semester-management',
                path: '/semester-management/semester-management',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__semester-management__semester-management__model.ts' */ '/usr/src/app/src/pages/semester-management/semester-management/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../semester-management/semester-management'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../semester-management/semester-management')
                      .default,
                exact: true,
              },
              {
                name: 'semester-class',
                path: '/semester-management/semester-class',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__semester-management__semester-class__model.ts' */ '/usr/src/app/src/pages/semester-management/semester-class/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../semester-management/semester-class'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../semester-management/semester-class').default,
                exact: true,
              },
              {
                name: 'semester-details',
                path: '/semester-management/semester-details',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__semester-management__semester-details__model.ts' */ '/usr/src/app/src/pages/semester-management/semester-details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../semester-management/semester-details'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../semester-management/semester-details').default,
                exact: true,
              },
              {
                name: 'join-klass-approve',
                path: '/semester-management/join-klass-approve',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__semester-management__join-klass-approve__model.ts' */ '/usr/src/app/src/pages/semester-management/join-klass-approve/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../semester-management/join-klass-approve'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../semester-management/join-klass-approve')
                      .default,
                exact: true,
              },
              {
                name: 'teacher',
                path: '/semester-management/teacher',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__semester-management__teacher__model.ts' */ '/usr/src/app/src/pages/semester-management/teacher/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../semester-management/teacher'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../semester-management/teacher').default,
                exact: true,
              },
              {
                name: 'student',
                path: '/semester-management/student',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__semester-management__student__model.ts' */ '/usr/src/app/src/pages/semester-management/student/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../semester-management/student'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../semester-management/student').default,
                exact: true,
              },
              {
                name: 'query-students',
                path: '/semester-management/query-students',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__semester-management__query-students__model.ts' */ '/usr/src/app/src/pages/semester-management/query-students/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../semester-management/query-students'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../semester-management/query-students').default,
                exact: true,
              },
              {
                name: 'create-semester',
                path: '/semester-management/create-semester',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__semester-management__create-semester__model.ts' */ '/usr/src/app/src/pages/semester-management/create-semester/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../semester-management/create-semester'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../semester-management/create-semester').default,
                exact: true,
              },
              {
                name: 'student-details',
                path: '/semester-management/common-student-details',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../semester-management/common-student-details'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../semester-management/common-student-details')
                      .default,
                exact: true,
              },
              {
                name: 'query-student-details',
                path: '/semester-management/query-student-details',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../semester-management/query-student-details'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../semester-management/query-student-details')
                      .default,
                exact: true,
              },
            ],
          },
          {
            path: '/orders',
            name: 'orders',
            icon: 'user',
            authority: ['ADMIN', 'SCHOOL'],
            routes: [
              {
                name: 'list',
                path: '/orders/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__orders__list__model.ts' */ '/usr/src/app/src/pages/orders/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../orders/list'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../orders/list').default,
                authority: ['ADMIN'],
                exact: true,
              },
              {
                name: 'batch-order-task',
                path: '/orders/batch-order-task',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__orders__batch-order-task__model.ts' */ '/usr/src/app/src/pages/orders/batch-order-task/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../orders/batch-order-task'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../orders/batch-order-task').default,
                authority: ['ADMIN'],
                exact: true,
              },
              {
                name: 'hebei-bank',
                path: '/orders/hebei-bank',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__orders__hebei-bank__model.ts' */ '/usr/src/app/src/pages/orders/hebei-bank/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../orders/hebei-bank'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../orders/hebei-bank').default,
                exact: true,
              },
              {
                name: 'month-order',
                path: '/orders/month-order',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__orders__month-order__model.ts' */ '/usr/src/app/src/pages/orders/month-order/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../orders/month-order'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../orders/month-order').default,
                authority: ['ADMIN'],
                exact: true,
              },
              {
                name: 'order-details',
                path: '/orders/order-details',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__orders__order-details__model.ts' */ '/usr/src/app/src/pages/orders/order-details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../orders/order-details'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../orders/order-details').default,
                authority: ['ADMIN'],
                exact: true,
              },
              {
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../404'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../404').default,
                exact: true,
              },
            ],
          },
          {
            path: '/device',
            name: 'device',
            icon: 'laptop',
            authority: ['admin'],
            routes: [
              {
                name: 'list',
                path: '/device/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__device__model.ts' */ '/usr/src/app/src/pages/device/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../device/list'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../device/list').default,
                exact: true,
              },
              {
                name: 'list-details',
                path: '/device/list-details',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__device__list-details__model.ts' */ '/usr/src/app/src/pages/device/list-details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__device__model.ts' */ '/usr/src/app/src/pages/device/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../device/list-details'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../device/list-details').default,
                exact: true,
              },
              {
                name: 'cards',
                path: '/device/cards',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__device__cards__model.ts' */ '/usr/src/app/src/pages/device/cards/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__device__model.ts' */ '/usr/src/app/src/pages/device/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../device/cards'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../device/cards').default,
                exact: true,
              },
              {
                name: 'pic-feedbacks',
                path: '/device/pic-feedbacks',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__device__pic-feedbacks__model.ts' */ '/usr/src/app/src/pages/device/pic-feedbacks/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__device__model.ts' */ '/usr/src/app/src/pages/device/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../device/pic-feedbacks'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../device/pic-feedbacks').default,
                exact: true,
              },
              {
                name: 'time-rule-setting',
                path: '/device/time-rule-setting',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__device__model.ts' */ '/usr/src/app/src/pages/device/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../device/time-rule-setting'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../device/time-rule-setting').default,
                exact: true,
              },
              {
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../404'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../404').default,
                exact: true,
              },
            ],
          },
          {
            path: '/system',
            name: 'system',
            icon: 'setting',
            authority: ['admin'],
            routes: [
              {
                name: 'menu',
                path: '/system/menus',
                icon: 'menu',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__system__menus__model.ts' */ '/usr/src/app/src/pages/system/menus/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../system/menus'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../system/menus').default,
                exact: true,
              },
              {
                name: 'authority',
                path: '/system/authority',
                icon: 'swap',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__system__authority__model.ts' */ '/usr/src/app/src/pages/system/authority/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../system/authority'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../system/authority').default,
                exact: true,
              },
              {
                name: 'appGroup',
                path: '/system/app-group',
                icon: 'database',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__system__app-group__model.ts' */ '/usr/src/app/src/pages/system/app-group/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../system/app-group'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../system/app-group').default,
                exact: true,
              },
              {
                name: 'adhibition',
                path: '/system/application',
                icon: 'appstore',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__system__application__model.ts' */ '/usr/src/app/src/pages/system/application/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../system/application'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../system/application').default,
                exact: true,
              },
              {
                name: 'armory',
                path: '/system/resource-management',
                icon: 'radar-chart',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__system__resource-management__model.ts' */ '/usr/src/app/src/pages/system/resource-management/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../system/resource-management'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../system/resource-management').default,
                exact: true,
              },
              {
                name: 'bulk',
                path: '/system/bulkImport',
                icon: 'download',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__system__bulkImport__model.ts' */ '/usr/src/app/src/pages/system/bulkImport/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../system/bulkImport'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../system/bulkImport').default,
                exact: true,
              },
              {
                name: 'wechat-reply',
                path: '/system/wechat-reply',
                icon: 'clock-circle',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__system__wechat-reply__model.ts' */ '/usr/src/app/src/pages/system/wechat-reply/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../system/wechat-reply'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../system/wechat-reply').default,
                exact: true,
              },
              {
                name: 'wechat-group',
                path: '/system/wechat-group',
                icon: 'wechat',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__system__wechat-group__model.ts' */ '/usr/src/app/src/pages/system/wechat-group/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../system/wechat-group'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../system/wechat-group').default,
                exact: true,
              },
              {
                name: 'wechat-application',
                path: '/system/wechat-application',
                icon: 'wechat',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__system__wechat-application__model.ts' */ '/usr/src/app/src/pages/system/wechat-application/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../system/wechat-application'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../system/wechat-application').default,
                exact: true,
              },
              {
                name: 'sendbox-state',
                path: '/system/sendbox-state',
                icon: 'message',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__system__sendbox-state__model.ts' */ '/usr/src/app/src/pages/system/sendbox-state/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../system/sendbox-state'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../system/sendbox-state').default,
                exact: true,
              },
              {
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../404'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../404').default,
                exact: true,
              },
            ],
          },
          {
            path: '/statement',
            name: 'statement',
            icon: 'ordered-list',
            authority: ['ADMIN', 'SCHOOL'],
            routes: [
              {
                name: 'order-statement',
                path: '/statement/order-statement',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__statement__order-statement__model.ts' */ '/usr/src/app/src/pages/statement/order-statement/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../statement/order-statement'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../statement/order-statement').default,
                exact: true,
              },
              {
                name: 'evaluation-statistics',
                path: '/statement/evaluation-statistics',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__statement__evaluation-statistics__model.ts' */ '/usr/src/app/src/pages/statement/evaluation-statistics/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../statement/evaluation-statistics'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../statement/evaluation-statistics').default,
                exact: true,
              },
              {
                name: 'leave-statistics',
                path: '/statement/leave-statistics',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__statement__leave-statistics__model.ts' */ '/usr/src/app/src/pages/statement/leave-statistics/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../statement/leave-statistics'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../statement/leave-statistics').default,
                exact: true,
              },
            ],
          },
          {
            path: '/leave',
            name: 'leave',
            icon: 'retweet',
            authority: ['SCHOOL'],
            routes: [
              {
                name: 'leave-flow',
                path: '/leave/leave-flow',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__leave__leave-flow__model.ts' */ '/usr/src/app/src/pages/leave/leave-flow/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../leave/leave-flow'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../leave/leave-flow').default,
                exact: true,
              },
              {
                name: 'leave-query',
                path: '/leave/leave-query',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__leave__leave-query__model.ts' */ '/usr/src/app/src/pages/leave/leave-query/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../leave/leave-query'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../leave/leave-query').default,
                exact: true,
              },
            ],
          },
          {
            path: '/user-management',
            name: 'user-management',
            icon: 'team',
            authority: ['admin', 'SCHOOL'],
            routes: [
              {
                name: 'information',
                path: '/user-management/information',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__user-management__information__model.ts' */ '/usr/src/app/src/pages/user-management/information/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../user-management/information'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../user-management/information').default,
                exact: true,
              },
              {
                name: 'teaching-staff',
                path: '/user-management/teaching-staff',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__user-management__teaching-staff__model.ts' */ '/usr/src/app/src/pages/user-management/teaching-staff/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../user-management/teaching-staff'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../user-management/teaching-staff').default,
                exact: true,
              },
              {
                name: 'role',
                path: '/user-management/role',
                icon: 'vertical-align-middle',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__user-management__role__model.ts' */ '/usr/src/app/src/pages/user-management/role/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../user-management/role'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../user-management/role').default,
                exact: true,
              },
              {
                name: 'sstudent',
                path: '/user-management/query-student',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__user-management__query-student__model.tsx' */ '/usr/src/app/src/pages/user-management/query-student/model.tsx').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../user-management/query-student'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../user-management/query-student').default,
                exact: true,
              },
              {
                name: 'student-details',
                path: '/user-management/student-details',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../user-management/student-details'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../user-management/student-details').default,
                exact: true,
              },
              {
                name: 'department',
                path: '/user-management/department',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__user-management__department__model.ts' */ '/usr/src/app/src/pages/user-management/department/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../user-management/department'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../user-management/department').default,
                exact: true,
              },
              {
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../404'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../404').default,
                exact: true,
              },
            ],
          },
          {
            path: '/m-school',
            name: 'mschool',
            icon: 'retweet',
            routes: [
              {
                name: 'send-notice',
                path: '/m-school/send-notice',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__m-school__send-notice__model.ts' */ '/usr/src/app/src/pages/m-school/send-notice/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../m-school/send-notice'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../m-school/send-notice').default,
                exact: true,
              },
              {
                name: 'home-work',
                path: '/m-school/home-work',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__m-school__home-work__model.ts' */ '/usr/src/app/src/pages/m-school/home-work/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../m-school/home-work'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../m-school/home-work').default,
                exact: true,
              },
              {
                name: 'custom',
                path: '/m-school/custom',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../m-school/custom'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../m-school/custom').default,
                exact: true,
              },
              {
                name: 'to-be-sent',
                path: '/m-school/to-be-sent',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__m-school__to-be-sent__model.ts' */ '/usr/src/app/src/pages/m-school/to-be-sent/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../m-school/to-be-sent'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../m-school/to-be-sent').default,
                exact: true,
              },
              {
                name: 'sent',
                path: '/m-school/sent',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__m-school__sent__model.ts' */ '/usr/src/app/src/pages/m-school/sent/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../m-school/sent'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../m-school/sent').default,
                exact: true,
              },
              {
                name: 'send-receive-details',
                path: '/m-school/send-receive-details',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__m-school__send-receive-details__model.ts' */ '/usr/src/app/src/pages/m-school/send-receive-details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../m-school/send-receive-details'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../m-school/send-receive-details').default,
                exact: true,
              },
            ],
          },
          {
            path: '/attendance',
            name: 'attendance',
            icon: 'team',
            routes: [
              {
                name: 'attendance-record',
                path: '/attendance/attendance-record',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__attendance__attendance-record__model.ts' */ '/usr/src/app/src/pages/attendance/attendance-record/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../attendance/attendance-record'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../attendance/attendance-record').default,
                exact: true,
              },
              {
                name: 'attendance-record',
                path: '/attendance/real-time-attendance-record',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__attendance__real-time-attendance-record__model.ts' */ '/usr/src/app/src/pages/attendance/real-time-attendance-record/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../attendance/real-time-attendance-record'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../attendance/real-time-attendance-record')
                      .default,
                exact: true,
              },
              {
                name: 'time-slot',
                path: '/attendance/time-slot',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__attendance__time-slot__model.ts' */ '/usr/src/app/src/pages/attendance/time-slot/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../attendance/time-slot'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../attendance/time-slot').default,
                exact: true,
              },
              {
                name: 'anomaly-users',
                path: '/attendance/anomaly-users',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__attendance__anomaly-users__model.ts' */ '/usr/src/app/src/pages/attendance/anomaly-users/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../attendance/anomaly-users'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../attendance/anomaly-users').default,
                exact: true,
              },
              {
                name: 'roll-call-search',
                path: '/attendance/roll-call-search',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__attendance__roll-call-search__model.ts' */ '/usr/src/app/src/pages/attendance/roll-call-search/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../attendance/roll-call-search'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../attendance/roll-call-search').default,
                exact: true,
              },
              {
                name: 'roll-call-details',
                path: '/attendance/roll-call-details',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__attendance__roll-call-details__model.ts' */ '/usr/src/app/src/pages/attendance/roll-call-details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../attendance/roll-call-details'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../attendance/roll-call-details').default,
                exact: true,
              },
            ],
          },
          {
            path: '/interaction',
            name: 'interaction',
            icon: 'setting',
            routes: [
              {
                name: 'message',
                path: '/interaction/message',
                routes: [
                  {
                    name: 'send-message',
                    path: '/interaction/message/send-message',
                    icon: 'swap',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__interaction__message__send-message__model.ts' */ '/usr/src/app/src/pages/interaction/message/send-message/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../interaction/message/send-message'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../interaction/message/send-message').default,
                    exact: true,
                  },
                  {
                    name: 'recommended-message',
                    path: '/interaction/message/recommended-message',
                    icon: 'swap',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__interaction__message__recommended-message__model.ts' */ '/usr/src/app/src/pages/interaction/message/recommended-message/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../interaction/message/recommended-message'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../interaction/message/recommended-message')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'template-message',
                    path: '/interaction/message/template-message',
                    icon: 'swap',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__interaction__message__template-message__model.ts' */ '/usr/src/app/src/pages/interaction/message/template-message/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../interaction/message/template-message'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../interaction/message/template-message')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'mass-student',
                    path: '/interaction/message/mass-student',
                    icon: 'swap',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__interaction__message__mass-student__model.ts' */ '/usr/src/app/src/pages/interaction/message/mass-student/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../interaction/message/mass-student'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../interaction/message/mass-student').default,
                    exact: true,
                  },
                  {
                    name: 'mass-teacher',
                    path: '/interaction/message/mass-teacher',
                    icon: 'swap',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__interaction__message__mass-teacher__model.ts' */ '/usr/src/app/src/pages/interaction/message/mass-teacher/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../interaction/message/mass-teacher'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../interaction/message/mass-teacher').default,
                    exact: true,
                  },
                  {
                    name: 'inbox',
                    path: '/interaction/message/inbox',
                    icon: 'swap',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__interaction__message__inbox__model.ts' */ '/usr/src/app/src/pages/interaction/message/inbox/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../interaction/message/inbox'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../interaction/message/inbox').default,
                    exact: true,
                  },
                  {
                    name: 'has-been-sent',
                    path: '/interaction/message/has-been-sent',
                    icon: 'swap',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__interaction__message__has-been-sent__model.ts' */ '/usr/src/app/src/pages/interaction/message/has-been-sent/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../interaction/message/has-been-sent'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../interaction/message/has-been-sent').default,
                    exact: true,
                  },
                  {
                    name: 'to-be-sent',
                    path: '/interaction/message/to-be-sent',
                    icon: 'swap',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__interaction__message__to-be-sent__model.ts' */ '/usr/src/app/src/pages/interaction/message/to-be-sent/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../interaction/message/to-be-sent'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../interaction/message/to-be-sent').default,
                    exact: true,
                  },
                  {
                    name: 'favorites',
                    path: '/interaction/message/favorites',
                    icon: 'swap',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__interaction__message__favorites__model.ts' */ '/usr/src/app/src/pages/interaction/message/favorites/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../interaction/message/favorites'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../interaction/message/favorites').default,
                    exact: true,
                  },
                  {
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../404'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../404').default,
                    exact: true,
                  },
                ],
              },
              {
                name: 'home-work',
                path: '/interaction/home-work',
                routes: [
                  {
                    name: 'assign-work',
                    path: '/interaction/home-work/assign-work',
                    icon: 'swap',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__interaction__home-work__assign-work__model.ts' */ '/usr/src/app/src/pages/interaction/home-work/assign-work/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../interaction/home-work/assign-work'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../interaction/home-work/assign-work').default,
                    exact: true,
                  },
                  {
                    name: 'to-be-send-work',
                    path: '/interaction/home-work/to-be-send-work',
                    icon: 'swap',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__interaction__home-work__to-be-send-work__model.ts' */ '/usr/src/app/src/pages/interaction/home-work/to-be-send-work/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../interaction/home-work/to-be-send-work'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../interaction/home-work/to-be-send-work')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'sented-work',
                    path: '/interaction/home-work/sented-work',
                    icon: 'swap',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__interaction__home-work__sented-work__model.ts' */ '/usr/src/app/src/pages/interaction/home-work/sented-work/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../interaction/home-work/sented-work'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../interaction/home-work/sented-work').default,
                    exact: true,
                  },
                  {
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../404'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../404').default,
                    exact: true,
                  },
                ],
              },
              {
                name: 'statistical-query',
                path: '/interaction/statistical-query',
                routes: [
                  {
                    name: 'class-send',
                    path: '/interaction/statistical-query/class-send',
                    icon: 'swap',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__interaction__statistical-query__class-send__model.ts' */ '/usr/src/app/src/pages/interaction/statistical-query/class-send/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../interaction/statistical-query/class-send'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../interaction/statistical-query/class-send')
                          .default,
                    exact: true,
                  },
                  {
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../404'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../404').default,
                    exact: true,
                  },
                ],
              },
            ],
          },
          {
            path: '/exam-score',
            name: 'examscore',
            icon: 'solution',
            authority: ['admin', 'SCHOOL'],
            routes: [
              {
                name: 'exam',
                path: '/exam-score/exam-management',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__exam-score__exam-management__model.ts' */ '/usr/src/app/src/pages/exam-score/exam-management/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__exam-score__model.ts' */ '/usr/src/app/src/pages/exam-score/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../exam-score/exam-management'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../exam-score/exam-management').default,
                exact: true,
              },
              {
                name: 'exam-details',
                path: '/exam-score/exam-management/exam-details',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__exam-score__exam-management__model.ts' */ '/usr/src/app/src/pages/exam-score/exam-management/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__exam-score__model.ts' */ '/usr/src/app/src/pages/exam-score/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../exam-score/exam-management/exam-details'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../exam-score/exam-management/exam-details')
                      .default,
                exact: true,
              },
              {
                name: 'score',
                path: '/exam-score/score-management',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__exam-score__model.ts' */ '/usr/src/app/src/pages/exam-score/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../exam-score/score-management'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../exam-score/score-management').default,
                exact: true,
              },
              {
                name: 'score-details',
                path: '/exam-score/score-management/score-details',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__exam-score__model.ts' */ '/usr/src/app/src/pages/exam-score/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../exam-score/score-management/score-details'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../exam-score/score-management/score-details')
                      .default,
                exact: true,
              },
              {
                name: 'score-update',
                path: '/exam-score/score-management/score-update',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__exam-score__model.ts' */ '/usr/src/app/src/pages/exam-score/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../exam-score/score-management/score-update'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../exam-score/score-management/score-update')
                      .default,
                exact: true,
              },
              {
                name: 'score-update',
                path: '/exam-score/score-management/score-upload',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__exam-score__model.ts' */ '/usr/src/app/src/pages/exam-score/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../exam-score/score-management/score-upload'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../exam-score/score-management/score-upload')
                      .default,
                exact: true,
              },
              {
                name: 'student-exam-search',
                path: '/exam-score/student-exam-search',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__exam-score__student-exam-search__model.ts' */ '/usr/src/app/src/pages/exam-score/student-exam-search/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__exam-score__model.ts' */ '/usr/src/app/src/pages/exam-score/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../exam-score/student-exam-search'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../exam-score/student-exam-search').default,
                exact: true,
              },
              {
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../404'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../404').default,
                exact: true,
              },
            ],
          },
          {
            path: '/developer-tools',
            name: 'developer-tools',
            icon: 'solution',
            authority: ['admin'],
            routes: [
              {
                name: 'api-docs',
                path: '/developer-tools/api-docs',
                routes: [
                  {
                    name: 'api-list',
                    path: '/developer-tools/api-docs/api-list',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../developer-tools/api-docs/api-list'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../developer-tools/api-docs/api-list').default,
                    exact: true,
                  },
                  {
                    name: 'api-diff',
                    path: '/developer-tools/api-docs/api-diff',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "layouts__BasicLayout" */ '../developer-tools/api-docs/api-diff'),
                          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../developer-tools/api-docs/api-diff').default,
                    exact: true,
                  },
                ],
              },
              {
                name: 'errcode-config',
                path: '/developer-tools/errcode-config',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__developer-tools__errcode-config__model.ts' */ '/usr/src/app/src/pages/developer-tools/errcode-config/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../developer-tools/errcode-config'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../developer-tools/errcode-config').default,
                exact: true,
              },
            ],
          },
          {
            name: 'dorm',
            path: '/dorm',
            icon: 'shop',
            authority: ['SCHOOL'],
            routes: [
              {
                name: 'menu',
                path: '/dorm/building',
                icon: 'table',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__dorm__building__model.ts' */ '/usr/src/app/src/pages/dorm/building/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../dorm/building'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../dorm/building').default,
                exact: true,
              },
              {
                name: 'menu',
                path: '/dorm/manager-list',
                icon: 'table',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__dorm__manager-list__model.ts' */ '/usr/src/app/src/pages/dorm/manager-list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../dorm/manager-list'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../dorm/manager-list').default,
                exact: true,
              },
              {
                name: 'menu',
                path: '/dorm/dorm-list',
                icon: 'file-text',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__dorm__dorm-list__model.ts' */ '/usr/src/app/src/pages/dorm/dorm-list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../dorm/dorm-list'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../dorm/dorm-list').default,
                exact: true,
              },
              {
                name: 'menu',
                path: '/dorm/dorm-management',
                hideInMenu: true,
                icon: 'share-alt',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__dorm__dorm-management__model.ts' */ '/usr/src/app/src/pages/dorm/dorm-management/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../dorm/dorm-management'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../dorm/dorm-management').default,
                exact: true,
              },
            ],
          },
          {
            name: 'quality',
            path: '/quality-evaluation',
            icon: 'file-sync',
            authority: ['SCHOOL'],
            routes: [
              {
                name: 'matter',
                path: '/quality-evaluation/matter',
                icon: 'project',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__quality-evaluation__model.ts' */ '/usr/src/app/src/pages/quality-evaluation/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../quality-evaluation/matter'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../quality-evaluation/matter').default,
                exact: true,
              },
              {
                name: 'query',
                path: '/quality-evaluation/evaluation-query',
                icon: 'bars',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__quality-evaluation__model.ts' */ '/usr/src/app/src/pages/quality-evaluation/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../quality-evaluation/evaluation-query'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../quality-evaluation/evaluation-query').default,
                exact: true,
              },
            ],
          },
          {
            path: '/',
            redirect: '/home',
            exact: true,
          },
          {
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__404" */ '../404'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../404').default,
            exact: true,
          },
        ],
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    routeChangeHandler(history.location);
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper1>
        <RendererWrapper0>
          <Router history={history}>{renderRoutes(routes, props)}</Router>
        </RendererWrapper0>
      </RendererWrapper1>
    );
  }
}
