import request from '@/utils/request'

export async function unbindParent(params: any) {
  return request(`/account/parent-students/${params.id}`, {
    method: 'DELETE',
    data: params,
  });
}

//获取七牛token
export async function qiniuToken(params: any) {
  return request(
    `/tool/oss/token`, {
    method: 'POST',
    data: params
  }
  )
}

// 货品列表
export async function getProducts(params: any) {
  return request(`/order/products`,{
    params
  });
}

export async function orderIsTryOn(params:any) {
  return request(`/order/telecom-orders/is-try-on`, {
    params
  });
}

// 订购
export async function startOrder(params: any) {
  return request(`/order/orders/v3`, {
    method: 'POST',
    data: params,
  });
}

// 获取用户详情
export async function getStudentInfo(params: any) {
  return request(`/account/users/${params.studentId}`, {
    params,
  });
}

// 修改用户
export async function updateUser(params: any) {
  return request(`/account/users/${params.id}`, {
    method: 'PUT',
    data: params,
  });
}

// 修改在校信息
export async function updateAtSchoolInfo(params: any) {
  // 暂无接口
  return request(`/school/semester-klass-students/${params.id}`, {
    method: 'PUT',
    data: params,
  });
}

// 获取学生详情
export async function getStudentDetails(params: any) {
  return request(`/school/semester-klass-students/student-detail/${params.studentId}`);
}

// 家长列表
export async function getParentInfo(params: any) {
  return request(`/account/students/${params.studentId}/parents?withOrderInfo=${params.withOrderInfo}`);
}

export async function getHebeiOrders(params: any) {
  return request(`/joinus/ipay/hebei/order/orders`,{
    params
  });
}

export async function getHebeiStudentBalance(params: any) {
  return request(`/ipay/consumption/hebei/student-balance`,{
    method: 'POST',
    data: params,
  });
}

export async function getHebeiStudentConsumption(params: any) {
  return request(`/ipay/consumption/hebei/student-consumption-records`,{
    method: 'POST',
    data: params,
  });
}

export async function hebeiInitTime(params: any) {
  return request(`/joinus/ipay/hebei/order/init/time`,{
    method: 'PUT',
    data: params,
  });
}

export async function requestSyncToIpay(params: any) {
  return request(`/snake-biz/sync/student-info-to-ipay`,{
    method: 'post',
    data: params,
  });
}
