import { Effect } from 'dva';
import { Reducer } from 'redux';

import { queryCurrent, query as queryUsers, updateUpwd, getUserByMobile } from '@/services/user';
import { getUserStorage } from '@/utils/utils';

export interface CurrentUser {
  avatar?: string;
  name?: string;
  title?: string;
  group?: string;
  signature?: string;
  tags?: {
    key: string;
    label: string;
  }[];
  unreadCount?: number;  
}

export interface UserModelState {
  currentUser?: CurrentUser;
  isShowEditPwdModal: boolean;
  userInfo: object
}

export interface UserModelType {
  namespace: 'user';
  state: UserModelState;
  effects: {
    fetch: Effect;
    fetchCurrent: Effect;
    updateUpwd: Effect;
    getUserByMobile: Effect
  };
  reducers: {
    saveCurrentUser: Reducer<UserModelState>;
    changeNotifyCount: Reducer<UserModelState>;
    updateState: Reducer<UserModelState>;
  };
}

const UserModel: UserModelType = {
  namespace: 'user',

  state: {
    currentUser: getUserStorage(),
    isShowEditPwdModal: false,
    userInfo: {}
  },

  effects: {
    *getUserByMobile({ payload, callback }, { call, put }){
      try {
        const result = yield call(getUserByMobile, payload)
        let response = { ...result.data, httpStatus: result.response.status }
        if (response.httpStatus === 200 && result && result.data) {
          yield put({
            type: 'updateState',
            payload: {
              userInfo: result.data
            }
          })
          return result.data || {}
        }
        return {}
      } catch (e) { }
    },
    *updateUpwd({ payload, callback }, { call, put }){
      try{
        const result = yield call(updateUpwd,payload)
        let response = { ...result.data, httpStatus: result.response.status }
        if(callback){
          callback(response.httpStatus === 200 ? 'success' : 'error')
        }
      }catch(e){}
    },
    *fetch(_, { call, put }) {
      const response = yield call(queryUsers);
      yield put({
        type: 'save',
        payload: response,
      });
    },
    *fetchCurrent(_, { call, put }) {
      const response = yield call(queryCurrent);
      // TODO: 获取当前用户信息
      // yield put({
      //   type: 'saveCurrentUser',
      //   payload: response,
      // });
    },
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload || {},
      };
    },
    changeNotifyCount(
      state = {
        currentUser: {},
      },
      action,
    ) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};

export default UserModel;
