/* eslint no-useless-escape:0 import/prefer-default-export:0 */
import _ from 'lodash';
import moment from 'moment';
import {getAppendSchoolId} from '@/utils/url-helper';
import { Icon } from 'antd';
import Cookies from 'js-cookie'

const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

const isUrl = (path: string): boolean => reg.test(path);

const isAntDesignPro = (): boolean => {
  if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return true;
  }
  return window.location.hostname === 'preview.pro.ant.design';
};

const getPagination = (response={}) => {
  const current = _.get(response, 'current');
  return {
    showTotal: (total: number) => `共 ${_.get(response,'total',0)} 条`,
    showQuickJumper: true,
    showSizeChanger: true,
    current: current ? _.get(response, 'current',1) : _.get(response, 'currentPage',1),
    total: _.get(response, 'total',10),
    pageSize: _.get(response, 'pageSize',10),
  }
}

const getImageMogr = (state=false) => {
  return state ? '?imageMogr2/thumbnail/x600>' : '?imageMogr2/thumbnail/x300>'
}

const toFixed2 = (num1: number,num2: number) => {
  const count = _.divide(_.toSafeInteger(num2),_.toSafeInteger(num1))
  console.log({num1,num2,count})
  if(!_.isFinite(count)){
    return 0
  }
  const numStr = `${count}`
  const result = parseFloat(numStr) * 100
  return Number(result.toFixed(2))
  // return Math.floor(count * 100) / 100
}

const closeWebPage = () => {
  window.open('', '_self', '');
  window.opener=null;
  window.close();
  return false;
  // if (navigator.userAgent.indexOf("MSIE") > 0) {//close IE
  //  if (navigator.userAgent.indexOf("MSIE 6.0") > 0) {
  //   window.opener = null;
  //   window.close();
  //  } else {
  //   window.open('', '_top');
  //   window.top.close();
  //  }
  // }
  // else if (navigator.userAgent.indexOf("Firefox") > 0) {//close firefox
  //  window.location.href = 'about:blank ';
  // } else {//close chrome;It is effective when it is only one.
  //  window.opener = null;
  //  window.open('', '_self');
  //  window.close();
  // }
 }

// 给官方演示站点用，用于关闭真实开发环境不需要使用的特性
const isAntDesignProOrDev = (): boolean => {
  const { NODE_ENV } = process.env;
  if (NODE_ENV === 'development') {
    return true;
  }
  return isAntDesignPro();
};

const alertMessageList = [
  "请先输入手机号，系统将自动匹配查找已有的用户信息。",
  '您输入的手机号在系统中不存在，请输入完整信息，系统将自动注册为新用户。',
  '您输入的手机号在系统中已存在，将自动建立关联。'
]

const transformIdCard = (text = '') => {
  if(!text){
    return ''
  }
  return text.replace(/^(.{6})(?:\d+)(.{4})$/, "$1****$2")
}

const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1472531_iwjtni32bw8.js', // 在 iconfont.cn 上生成
});

const MySymbolIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1555743_oyme96cz6t.js', // 在 iconfont.cn 上生成
});

enum dormTreeType {
  BUILDING = '校',
  FLOOR = '楼',
  ROOM = '层'
}

// 菜单展示
const isDevelopmentEnv = (): boolean => {
  const { NODE_ENV } = process.env;
  return NODE_ENV === 'development'
}
// 测试环境和正式环境
const isUatEnv = () => {
  // const { UAT } = process.env;
  return UAT === 'UAT'
}

const getCurrentPathname = () => {
  return _.get(window,'g_history.location.pathname')
}

// 是否显示按钮
const isShowBtn = (path='', name='',currentPathname=''): boolean => {
  const pathname = currentPathname || getCurrentPathname()
  path = path || `${pathname}:${name}`
  // console.log('isShowBtn path:',path)
  let buttonPaths: any = localStorage.getItem('buttonPaths')
  // if(isDevelopmentEnv()){
    // 本地测试显示所有按钮
    // return true;
  // }
  if (isJsonString(buttonPaths)) {
    return _.includes(JSON.parse(buttonPaths), path)
  }
  return _.includes(buttonPaths, path)
}

// Table组件是否显示滚动条
const isShowScrollBar = <T = any>(list: T[]) => {
  return true
  // return _.isArray(list) ? list.length > 0 : false
}

// 获取当前登录身份
const currentIdentity = (): string | null => {
  return localStorage.getItem('authority');
}

const isAdmin = () : boolean => {
  return currentIdentity() === 'ADMIN';
}

const isSchool = () : boolean => {
  return currentIdentity() === 'SCHOOL';
}

const isSuperAdmin = () : boolean => {
  return currentIdentity() === 'SUPER_ADMIN';
}
// 学校身份视图下，不展示学校id的搜索与列表展示
const removeSchool = (columns: any) => {
  if (isSchool()) {
    _.remove(columns, function (o: any) {
      return o.key == 'schoolId' || o.key == 'schoolName'
    })
  }
}

// 排序
const mySorter = (a: any, b: any) => {
  let acreatedAt: string = _.get(a, 'createdAt', '')
  let bcreateAt: string = _.get(b, 'createdAt', '')
  return (moment(acreatedAt) as any) - (moment(bcreateAt) as any)
}

// 时间转换
const formatDateTime = (date: string) => {
  if (!!date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  }
  return ''
}

// 时间转换
const enumDateTime = (list: any[]) => {
  _.forEach(list, (val) => {
    val.createdAt && (val.createdAt = formatDateTime(val.createdAt))
    val.updatedAt && (val.updatedAt = formatDateTime(val.updatedAt))
    val.beginAt && (val.beginAt = formatDateTime(val.beginAt))
    val.endAt && (val.endAt = formatDateTime(val.endAt))
    val.sendTime && (val.sendTime = formatDateTime(val.sendTime))
    val.scheduledTime && (val.scheduledTime = formatDateTime(val.scheduledTime))
    val.confirmedAt && (val.confirmedAt = formatDateTime(val.confirmedAt))
    val.finishedAt && (val.finishedAt = formatDateTime(val.finishedAt))
    val.cancelledAt && (val.cancelledAt = formatDateTime(val.cancelledAt))
    val.closedAt && (val.closedAt = formatDateTime(val.closedAt))
    val.succeededAt && (val.succeededAt = formatDateTime(val.succeededAt))
    val.readAt && (val.readAt = formatDateTime(val.readAt))
  })
}

// 根据status响应状态码判断
const getStatus = (response: any, code: string | number) => {
  if (response && response.httpStatus == code) {
    return 'success'
  }
  return 'error'
}

const setUserStorage = (obj: any) => {
  let userInfo = obj;
  localStorage.setItem("userInfo", userInfo);
};

const getOffsetHeight = () => {
  let offsetHeight = window.document.body.offsetHeight;
  return offsetHeight;
}

const isJsonString = (str: string): boolean => {
  try {
    if (typeof JSON.parse(str) == "object") {
      return true;
    }
  }
  catch (e) {
  }
  return false;
}

const getUserStorage = () => {
  let userInfo: any = localStorage.getItem("userInfo")
  let data = {}
  if (isJsonString(userInfo)) {
    data = JSON.parse(userInfo)
  }
  return {...data, schoolId: (getAppendSchoolId() || (data && data.schoolId) || 0)}
};

const setAccessToken = (obj: any, isChecked: boolean) => {
  let accessToken = obj;
  localStorage.setItem("isChecked",JSON.stringify(isChecked))
  if(isChecked){
    localStorage.setItem("accessToken", accessToken);
  }else{
    Cookies.set('accessToken', accessToken);
    // sessionStorage.setItem("accessToken", accessToken)
  }
};

// schoolId，优先级url > localstorage
const getSchoolId = () : number | null=> {
  let userInfo = getUserStorage()
  let localParams = _.get(userInfo, 'schoolId', 0);
  let schoolId = localParams;
  return getAppendSchoolId() || schoolId;
};

const getSchoolId2 = () => {
  return getSchoolId()
}

const getAccessToken = () => {
  let isChecked = localStorage.getItem('isChecked')

  isChecked = isChecked && JSON.parse(isChecked || '') || false
  let accessToken = isChecked ? localStorage.getItem("accessToken") : Cookies.get('accessToken')
  return accessToken
};

const getIdentity = (text: number, type: string) => {
  let binaryNum = text && text.toString(2).split('').reverse() || [];
  let allIdentity = ['学生', '家长', '教师', '管理员', '超级管理员'];
  let allIdentityRole = ['isStudent', 'isParent', 'isSchool', 'isAdmin', 'isSuperAdmin'];
  let identityList: any[] = [];
  if (!_.isEmpty(binaryNum) && type == 'getString') {
    identityList = binaryNum.map((b: any, index: number) => {
      if (b == 1) {
        return allIdentity[index]
      } else {
        return null
      }
    })
  } else if (!_.isEmpty(binaryNum) && type == 'getArray') {
    identityList = binaryNum.map((b: any, index: number) => {
      if (b == 1) {
        return allIdentityRole[index]
      } else {
        return null
      }
    })
  }
  return _.compact(identityList)
}

// admin登录子菜单显示
const getSchoolChildrenMenu = (menuData: any[], isSwitchSchool=false) => {
  const adminSchoolBasicPath = [
    '/school-management/create-school',
    '/school-management/basic-information',
    '/school-management/school-detail'
  ]
  const menu = _.map(menuData, menuItem => {
    if(menuItem.path === '/school-management'){
      const schoolMenuChildren = _.filter(menuItem.children, child => {
        if(isSwitchSchool){
          return !_.includes(['/school-management/create-school','/school-management/basic-information'], child.path);
        }
        return _.includes(adminSchoolBasicPath, child.path);
      })
      return { ...menuItem, children: schoolMenuChildren }
    }

    if(menuItem.path === '/system'){
      const schoolMenuChildren = _.filter(menuItem.children, child => {
        if(isSwitchSchool){
          return !_.includes(['/system/wechat-reply'], child.path);
        }
        return !_.includes(['/system/bulkImport'], child.path);
      })
      return { ...menuItem, children: schoolMenuChildren }
    }
    return menuItem
  })
  return menu
}

// admin登录后菜单显示处理
const handleAdminMenu = (menuData: any[]) => {
  const adminSchoolPath = [
    '/home',
    '/system',
    '/school-management',
    '/semester-management',
    '/attendance',
    '/exam-score',
    '/m-school'
  ]

  let schoolMenuData = _.filter(menuData, menu => {
    return _.includes(adminSchoolPath,menu.path)
  })
  schoolMenuData = getSchoolChildrenMenu(schoolMenuData, true)

  let adminMenuData = _.filter(menuData, menu => {
    return !_.includes(['/semester-management','/attendance', '/exam-score', '/m-school'],menu.path)
  })

  adminMenuData = getSchoolChildrenMenu(adminMenuData)

  menuData = getSchoolId() ? schoolMenuData : adminMenuData
  console.log('处理后的 menuData',menuData)
  return menuData
}

const filterMenuTree = (data: any) => {
  let temp = _.clone(data)
  temp = temp.map((obj: any) => {
    if (_.isArray(obj.children) && !_.isEmpty(obj.children)) {
      obj.children = filterMenuTree(obj.children)
    } else {
      delete obj.children
    }
    return obj;
  })
  return temp;
};

const filterMenu = (data: any) => {
  let temp = _.clone(data)
  temp = temp.map((obj: any) => {
    if (!obj.authority) {
      obj.authority = obj.identities;
    }
    if (_.isArray(obj.children) && !_.isEmpty(obj.children)) {
      const validChildrens = _.filter(obj.children, child => child.type != "BUTTON");
      obj.children = filterMenu(validChildrens)
    } else {
      delete obj.children
    }
    return obj;
  })
  return temp;
};

const filterAuthorityButtons = (data: any) => {
  let buttonPaths: any[] = []
  let temp = _.clone(data)
  temp = temp.map((obj: any) => {
    if(obj.hasMenu && obj.type=='BUTTON') {
      buttonPaths.push(obj.path);
    } else {
      if (_.isArray(obj.children) && !_.isEmpty(obj.children)) {
        buttonPaths = buttonPaths.concat(filterAuthorityButtons(obj.children))
      }
    }
  })
  return _.uniq(buttonPaths);
};

const isEmptyTree = (tree) => {
  // if(tree.hasMenu){
  //   return _.every(tree.children, (child)=> isEmptyTree(child))
  // }
  return !tree.hasMenu && _.every(tree.children, (child)=> isEmptyTree(child))
}
const isFullTree = (tree) => {
  if (tree.hasMenu) {
    return _.every(tree.children, (child)=> isFullTree(child))
  }
  return false
}
const isHalfTree = (tree: any) => {
  return !isFullTree(tree) && !isEmptyTree(tree)
}


const addArgs = (menuData: any[]) => {
  return menuData.map((m: { children: any; }) => {
    if (!_.isEmpty(m.children)) {
      m.children = addArgs(m.children)
    }
    return {
      ...m,
      isFullTree: isFullTree(m),
      isEmptyTree: isEmptyTree(m),
      isHalfTree: isHalfTree(m)
    }
  })
}

const filterMenuIds = (data: any) => {
  let newData = addArgs(data)
  return newData
};
const filterIds = (newData: any) => {
  let deepData : any[] = []
  newData.map((obj: any) => {
    deepData.push(obj)
    if (_.isArray(obj.children) && !_.isEmpty(obj.children)) {
      deepData = deepData.concat(filterIds(obj.children))
    }
  })
  return _.uniq(deepData);
}


const getHalfkeysAndCheckedKeys = (e: any, menuList: any[], anthorityList: string[]) => {
  // console.log('e',e)
  let checkedIds = _.get(anthorityList, '0', [])
  let halfCheckedIds = _.get(anthorityList, '1', [])
  // 先找到当前操作的节点
  let currentNodeId = _.get(e, 'node.props.eventKey')
  // 先找到当前操作的节点
  let currentNodeState = _.get(e, 'checked')
  let nodeChild = _.get(e, 'node.props.dataRef.children')
  let currentNodePosition = _.get(e, 'node.props.pos', '').split("-")
  // 如果 currentNodeState 是选中状态，则他的子孙都要选中，同时需要判断是否祖先被选中
  let childrenIds = handleMenuData(nodeChild)
  if (currentNodeState) {
    checkedIds = _.uniq([...checkedIds, ...childrenIds, currentNodeId])
  } else {
  let childrenIds = handleMenuData(nodeChild)
    checkedIds = _.difference(checkedIds, [...childrenIds, currentNodeId])
    _.remove(halfCheckedIds, function(n) { return n == currentNodeId});
  }
  // currentNodePosition 判断有几位4位表示点击的是最里层的节点，三位表示点击的中间层的节点，两位表示点击的最外层的节点
  let currentNodePositionLength = currentNodePosition.length
  switch (currentNodePositionLength) {
    case 2:
        // 长度为2则是最外层的节点，只用添加或者删除对应的children的节点不用关系出现半选的状态
      break;
      case 3:
        // 长度为3则是中间层的节点，判断自身选中状态及兄弟元素的选中状态去渲染父级的选中半选状态
        // 获取兄弟元素的id
        let brotherIds = _.map(menuList[currentNodePosition[1]].children, 'id')
        let brotherCheckedLength = _.intersection(checkedIds, brotherIds).length
        let brotherHalfLength = _.intersection(halfCheckedIds, brotherIds).length
        if (brotherCheckedLength == 0) {
          _.remove(checkedIds, function(n) { return n == menuList[currentNodePosition[1]].id});
          if(brotherHalfLength === 0){
            _.remove(halfCheckedIds, n => n == menuList[currentNodePosition[1]].id)
          }

        } else if(brotherCheckedLength > 0 && brotherCheckedLength < brotherIds.length) {
          halfCheckedIds = _.uniq([...halfCheckedIds, menuList[currentNodePosition[1]].id])
          _.remove(checkedIds, function(n) {
            return n == menuList[currentNodePosition[1]].id
          });
        } else {
          checkedIds = _.uniq([...checkedIds, menuList[currentNodePosition[1]].id])
        }
        break;
        case 4:
        // 长度为4则是最底层的节点，判断自身选中状态及兄弟元素的选中状态去渲染父级的选中半选状态
        // 获取兄弟元素的id
        let brotherIds1 = _.map(menuList[currentNodePosition[1]].children[currentNodePosition[2]].children, 'id')
        let brotherCheckedLength1 = _.intersection(checkedIds, brotherIds1).length

        if (brotherCheckedLength1 == 0) {
          // 兄弟元素都没选中，则父节点选中状态删除，父节点的半选状态也删除
          _.remove(checkedIds, function(n) { return n == menuList[currentNodePosition[1]].children[currentNodePosition[2]].id});
          _.remove(halfCheckedIds, function(n) { return n == menuList[currentNodePosition[1]].children[currentNodePosition[2]].id});
          // 判断祖节点的状态
          let parentBrotherIds = _.map(menuList[currentNodePosition[1]].children, 'id')
          let parentBrotherCheckedLength = _.intersection(checkedIds, parentBrotherIds).length
          let parentBrotherHalfLength = _.intersection(halfCheckedIds, parentBrotherIds).length
          if (parentBrotherCheckedLength == 0) {
            _.remove(checkedIds, function(n) { return n == menuList[currentNodePosition[1]].id});
            if(parentBrotherHalfLength == 0){
              _.remove(halfCheckedIds, n => n == menuList[currentNodePosition[1]].id)
            }

          } else if(parentBrotherCheckedLength > 0 && parentBrotherCheckedLength < parentBrotherIds.length) {
            halfCheckedIds = _.uniq([...halfCheckedIds, menuList[currentNodePosition[1]].id])
            _.remove(checkedIds, function(n) { return n == menuList[currentNodePosition[1]].id});
          } else {
            checkedIds = _.uniq([...checkedIds, menuList[currentNodePosition[1]].id])
          }
        } else if(brotherCheckedLength1 > 0 && brotherCheckedLength1 < brotherIds1.length) {
          halfCheckedIds = _.uniq([...halfCheckedIds, menuList[currentNodePosition[1]].children[currentNodePosition[2]].id])
          _.remove(checkedIds, function(n) { return n == menuList[currentNodePosition[1]].children[currentNodePosition[2]].id});
          halfCheckedIds = _.uniq([...halfCheckedIds, menuList[currentNodePosition[1]].id])
          _.remove(checkedIds, function(n) { return n == menuList[currentNodePosition[1]].id});
        } else {
          checkedIds = _.uniq([...checkedIds, menuList[currentNodePosition[1]].children[currentNodePosition[2]].id])
          // 判断祖节点的状态
          let parentBrotherIds1 = _.map(menuList[currentNodePosition[1]].children, 'id')
          let parentBrotherCheckedLength1 = _.intersection(checkedIds, parentBrotherIds1).length
          if (parentBrotherCheckedLength1 == 0) {
            _.remove(checkedIds, function(n) { return n == menuList[currentNodePosition[1]].id});
          } else if(parentBrotherCheckedLength1 > 0 && parentBrotherCheckedLength1 < parentBrotherIds1.length) {
            halfCheckedIds = _.uniq([...halfCheckedIds, menuList[currentNodePosition[1]].id])
            _.remove(checkedIds, function(n) { return n == menuList[currentNodePosition[1]].id});
          } else {
            checkedIds = _.uniq([...checkedIds, menuList[currentNodePosition[1]].id])
          }
        }
        break;
    default:
      break;
  }

  // console.log({
  //   anthorityList,
  //   checkedIds,
  //   halfCheckedIds,
  //   currentNodePosition,
  //   currentNodePositionLength,

  // })
   // 避免halfCheckedIds和checkedIds同时存在相同的节点
    const intersections = _.intersection(checkedIds,halfCheckedIds)
   _.remove(halfCheckedIds, function(n) { return _.includes(intersections,n)});

  return [checkedIds, halfCheckedIds]
}

const filterAllMenuIds = (data: any) => {
  let menuIds: any[] = []
  let temp = _.clone(data)
  temp.map((obj: any) => {
    if(obj.hasMenu) {
      menuIds.push(obj.id);
      if (_.isArray(obj.children) && !_.isEmpty(obj.children)) {
        menuIds = menuIds.concat(filterAllMenuIds(obj.children))
      }
    }
  });
  return _.uniq(menuIds);
}

// 所有id数组
const handleMenuData = (list: any) => {

  let menuIds: any[] = []
  let temp = _.clone(list)
  temp.map((item: any)=>{
    menuIds.push(item.id)
    if(_.isArray(item.children) && !_.isEmpty(item.children)){
      menuIds=menuIds.concat(handleMenuData(item.children))
    }
  })
  return _.uniq(menuIds)
}


const tagsFromServer = [
  { id: 0, name: '学生评语' },
  { id: 1, name: '卫生保健' },
  { id: 2, name: '考试通知' },
  { id: 3, name: '考试总结' },
];

const roleColors = { '学生': 'yellow', '教师': 'cyan', '家长': 'orange', '管理员': 'pink', '超级管理员': 'red' };

// Form表单验证
const checkIdCard = (rule: Boolean, value: string, callback: Function) => {
  //15位数身份证验证正则表达式：
  let isIDCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

  // \d{15}(\d{2}[0-9]X)?
  if (isIDCard.test(value) || _.size(value) === 0) {
    callback();
  } else {
    callback('请输入正确格式的身份证号');
  }
};

const checkMobile = (rule: Boolean, value: string, callback: Function) => {
  let re = /^1[3456789]\d{9}$/;
  if (re.test(value) || _.size(value) === 0) {
    callback();
  } else {
    callback('请输入正确格式的11位手机号');
  }
};

// 重组dormTree数据
const recDormTree = (data: any) => {
  let temp = _.clone(data)
  temp.map((obj: any) => {
    if(obj.name) {
      obj["title"] = obj.name;
      obj["label"] = obj.name;
      obj["value"] = obj.name;
      obj["key"] = obj.id;
      obj["children"] = obj.childs;
      if (_.isArray(obj.childs) && !_.isEmpty(obj.childs)) {
        recDormTree(obj.childs)
      }
    }
  })
  return temp;
};

const toChinesNum = (num) => {
  let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; //changeNum[0] = "零"
  let unit = ["", "十", "百", "千", "万"];
  num = parseInt(num);
  let getWan = (temp) => {
      let strArr = temp.toString().split("").reverse();
      let newNum = "";
      for (var i = 0; i < strArr.length; i++) {
          newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
      }
      return newNum;
  }
  let overWan = Math.floor(num / 10000);
  let noWan = num % 10000;
  if (noWan.toString().length < 4) noWan = "0" + noWan;
  return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);

}

const relationList =
['爸爸', '妈妈', '爷爷', '奶奶','外公', '外婆',  '叔叔', '婶婶', '舅舅', '舅妈', '姑姑', '姑父', '姨妈', '姨父', '哥哥', '姐姐', '亲属']

const httpStatus = {
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  418: "I'm a teapot",
  422: 'Unprocessable Entity',
  425: 'Too Early',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  510: 'Not Extended',
  511: 'Network Authentication Required'
}

export {
  isAntDesignProOrDev,
  setUserStorage,
  getSchoolId,
  filterMenu,
  getUserStorage,
  setAccessToken,
  getAccessToken,
  isAntDesignPro,
  isUrl,
  formatDateTime,
  getStatus,
  enumDateTime,
  mySorter,
  tagsFromServer,
  getIdentity,
  roleColors,
  getOffsetHeight,
  getSchoolId2,
  removeSchool,
  currentIdentity,
  isAdmin,
  isSchool,
  isSuperAdmin,
  filterMenuIds,
  filterMenuTree,
  filterAuthorityButtons,
  isShowScrollBar,
  isDevelopmentEnv,
  isShowBtn,
  checkIdCard,
  checkMobile,
  handleMenuData,
  filterAllMenuIds,
  recDormTree,
  dormTreeType,
  MySymbolIcon,
  transformIdCard,
  isUatEnv,
  filterIds,
  getHalfkeysAndCheckedKeys,
  alertMessageList,
  toChinesNum,
  handleAdminMenu,
  closeWebPage,
  relationList,
  MyIcon,
  toFixed2,
  getImageMogr,
  getPagination,
  getCurrentPathname,
  httpStatus
};
