import dva from 'dva';
import { Component } from 'react';
import createLoading from 'dva-loading';
import history from '@tmp/history';

let app = null;

export function _onCreate() {
  const plugins = require('umi/_runtimePlugin');
  const runtimeDva = plugins.mergeConfig('dva');
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    ...(window.g_useSSR ? { initialState: window.g_initialData } : {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach(plugin => {
    app.use(plugin);
  });
  
  app.model({ namespace: 'addParent', ...(require('/usr/src/app/src/models/addParent.ts').default) });
app.model({ namespace: 'apiDocs', ...(require('/usr/src/app/src/models/apiDocs.ts').default) });
app.model({ namespace: 'custom', ...(require('/usr/src/app/src/models/custom.ts').default) });
app.model({ namespace: 'global', ...(require('/usr/src/app/src/models/global.ts').default) });
app.model({ namespace: 'login', ...(require('/usr/src/app/src/models/login.ts').default) });
app.model({ namespace: 'menu', ...(require('/usr/src/app/src/models/menu.ts').default) });
app.model({ namespace: 'mschool', ...(require('/usr/src/app/src/models/mschool.ts').default) });
app.model({ namespace: 'ordersCount', ...(require('/usr/src/app/src/models/ordersCount.ts').default) });
app.model({ namespace: 'page2menu', ...(require('/usr/src/app/src/models/page2menu.ts').default) });
app.model({ namespace: 'setting', ...(require('/usr/src/app/src/models/setting.ts').default) });
app.model({ namespace: 'student', ...(require('/usr/src/app/src/models/student.ts').default) });
app.model({ namespace: 'studentDetails', ...(require('/usr/src/app/src/models/studentDetails.ts').default) });
app.model({ namespace: 'switchSchool', ...(require('/usr/src/app/src/models/switchSchool.ts').default) });
app.model({ namespace: 'user', ...(require('/usr/src/app/src/models/user.ts').default) });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
